import React from "react";
import DataStore from "../scripts/DataStore.js";
import * as DataTypes from "./DataTypes.js";
import FileOperations from "../scripts/FileOperations.js";
import PopupContainer from "../scripts/PopupContainer.js";
import Preferences from "../scripts/Preferences.js";
import BranchTemplates from "../scripts/BranchTemplates.js";
import MemberManager from "../scripts/MemberManager.js";
import MissionManager from "../scripts/MissionManager.js";
import * as Utilities from "./Utilities.js";
import "../styles/Header.css";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.MenuList = [
      [0, "FileMenuDialog", "FileMenuButton", "File"],
      [1, "EditMenuDialog", "EditMenuButton", "Project"],
      [2, "ViewMenuDialog", "ViewMenuButton", "View"],
      [3, "HelpMenuDialog", "HelpMenuButton", "Help"]
    ];

    this.state = {
      MembersListVisible: false,
      MissionListVisible: false
    };

    this.RefreshHandler = this.RefreshHandler.bind(this);
    this.FormSubmitHandler = this.FormSubmitHandler.bind(this);
    this.CloseMemberListHandler = this.CloseMemberListHandler.bind(this);
    this.CloseMissionListHandler = this.CloseMissionListHandler.bind(this);
    this.ViewMembers = this.ViewMembers.bind(this);
    this.ViewMissions = this.ViewMissions.bind(this);
  }

  componentDidMount() {
    let _body = document.body;
    _body.onmousemove = e => {
      for (let i = 0; i < this.MenuList.length; i++) {
        let _elem = document.getElementById(this.MenuList[i][1]);
        if (_elem.style.visibility === "visible") {
          if (e.clientX > _elem.offsetLeft + _elem.offsetWidth + 125)
            _elem.style.visibility = "hidden";
          else if (e.clientY > _elem.offsetTop + _elem.offsetHeight + 125)
            _elem.style.visibility = "hidden";
        }
      }
    };
    this.NewFile();
  }

  componentDidUpdate() {
    if (DataStore.User.ReadOnly) document.body.onmousemove = null;
  }

  ToggleMenu(index) {
    let _dialog = document.getElementById(this.MenuList[index][1]);
    let _button = document.getElementById(this.MenuList[index][2]);

    if (index > 0) _dialog.style.left = _button.offsetLeft + "px";

    if (_dialog.style.visibility === "visible")
      _dialog.style.visibility = "hidden";
    else {
      for (let i = 0; i < this.MenuList.length; i++) {
        if (i !== index)
          document.getElementById(this.MenuList[i][1]).style.visibility =
            "hidden";
      }
      _dialog.style.visibility = "visible";
    }
  }

  HideAllMenus() {
    for (let i = 0; i < this.MenuList.length; i++)
      document.getElementById(this.MenuList[i][1]).style.visibility = "hidden";
  }

  FormSubmitHandler(e) {
    e.preventDefault();
    const _data = new FormData(e.target);

    fetch(DataStore.DOMAIN + "process/write.php", {
      method: "POST",
      body: _data
    });
  }

  RefreshHandler() {
    this.props.Refresh();
  }

  DefaultBranches() {
    DataStore.User.Branches.push(BranchTemplates.Security());
    DataStore.User.Branches.push(BranchTemplates.Science());
    DataStore.User.Branches.push(BranchTemplates.Exploration());
    DataStore.User.Branches.push(BranchTemplates.Sourcing());
    DataStore.User.Branches.push(BranchTemplates.Engineering());
  }

  NewFile() {
    return (
      <div className="popupContainer_InputTable">
        <div
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 3,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <input id="defaultBranchCheckbox" type="checkbox" defaultChecked />{" "}
          Would you like to insert the Default Branches
        </div>
        {DataStore.User.Branches.length > 1 ? (
          <div
            style={{
              gridColumnStart: 1,
              gridColumnEnd: 3,
              background: "darkred",
              padding: "0.25em",
              color: Utilities.rgbaAsString(255, 0, 0, 1)
            }}
          >
            WARNING: All unsaved data will be lost.
          </div>
        ) : (
          ""
        )}
        <div className="popupContainer_ButtonRow">
          <div
            className="popupContainer_Button"
            onClick={() => {
              this.NewFileHandler();
            }}
          >
            CONTINUE
          </div>
          <div
            className="popupContainer_Button"
            onClick={() => PopupContainer.HidePopup()}
          >
            CANCEL
          </div>
        </div>
      </div>
    );
  }

  NewFileHandler() {
    DataStore.User = new DataTypes.UserData();
    let _checkbox = document.getElementById("defaultBranchCheckbox");
    if (_checkbox.checked) this.DefaultBranches();
    this.RefreshHandler();
    PopupContainer.HidePopup();
    PopupContainer.ShowPopup(
      "PREFERENCES",
      <Preferences Refresh={this.RefreshHandler} />
    );
  }

  UpdateLink(checked) {
    let _host = window.location.host;
    let _id = !checked ? DataStore.User.PublishId : Utilities.Guid();
    let _link = "https://" + _host + "?view=" + _id;
    return _link;
  }

  Publish() {
    return (
      <div className="popupContainer_InputTable">
        <div className="popupContainer_ButtonRow">
          Generate a read-only link to share your crew manager project with your
          team.
        </div>
        <div
          className="pupupContainer_InputLabel"
          style={{ justifyContent: "flex-end", paddingTop: "0.25em" }}
        >
          <input
            id="newPublishIdCheckbox"
            type="checkbox"
            defaultChecked={false}
            onClick={e => {
              document.getElementById(
                "newPublishIdUrlTextbox"
              ).value = this.UpdateLink(e.target.checked);
            }}
          />{" "}
        </div>
        <div
          className="pupupContainer_InputObject"
          style={{ background: "rgba(0,0,0,0)", textAlign: "left" }}
        >
          Check this box to generate a unique id. Otherwise publish using your
          default project id. Note that unique id's are for one time uses where
          as your project id will continue to update each time you publish using
          that id.
        </div>
        <div className="popupContainer_InputLabel">&nbsp;</div>
        <input
          id="newPublishIdUrlTextbox"
          className="popupContainer_InputObject"
          style={{ display: "none" }}
          type="text"
          readOnly
          value={this.UpdateLink(false)} //<-- Always false here!!!
        />
        <div
          id="copyMessage"
          className="popupContainer_ButtonRow"
          style={{ color: "lime" }}
        />
        <div className="popupContainer_ButtonRow">
          <div
            id="publishButton"
            className="popupContainer_Button"
            onClick={() => {
              let _textBox = document.getElementById("newPublishIdUrlTextbox");
              let _file = new FileOperations();
              let _path =
                "../upload/publish/" + _textBox.value.split("=")[1] + ".json";

              DataStore.User.ReadOnly = true;
              _file.WriteFile(_path, DataStore.User);
              DataStore.User.ReadOnly = false;

              _textBox.style.display = "block";
              _textBox.select();
              document.execCommand("copy");
              document.getElementById("copyMessage").innerHTML =
                "The read-only link has been copied to the clipboard.";

              document.getElementById("newPublishIdCheckbox").disabled = true;
              document.getElementById("cancelButton").innerHTML = "CLOSE";
              document.getElementById("publishButton").style.display = "none";
            }}
          >
            PUBLISH
          </div>
          <div
            id="cancelButton"
            className="popupContainer_Button"
            onClick={() => PopupContainer.HidePopup()}
          >
            CANCEL
          </div>
        </div>
      </div>
    );
  }

  ViewMembers() {
    DataStore.MissionsZOrder = 0;
    DataStore.MembersZOrder = 1;
    this.setState({
      MembersListVisible: true
    });
  }

  ViewMissions() {
    DataStore.MissionsZOrder = 1;
    DataStore.MembersZOrder = 0;
    this.setState({
      MissionListVisible: true
    });
  }

  GetMenuChildren(index) {
    let _items = [];
    let _counter = 0;
    switch (index) {
      case 0:
        _items.push(
          <div
            key={"GetMenuChildren_" + index + _counter++}
            className="header_DialogLineItem"
            onClick={() =>
              PopupContainer.ShowPopup("CREATE NEW PROJECT", this.NewFile())
            }
          >
            New
          </div>
        );
        _items.push(
          <div
            key={"GetMenuChildren_" + index + _counter++}
            className="header_DialogLineItem"
            onClick={() => {
              let _file = new FileOperations();
              _file.OpenFile(this.RefreshHandler);
            }}
          >
            Open...
          </div>
        );
        if (DataStore.User.Branches.length > 0) {
          _items.push(
            <div
              key={"GetMenuChildren_" + index + _counter++}
              className="header_DialogLineItem"
              onClick={() => {
                let _file = new FileOperations();
                let _fileName =
                  DataStore.User.OrganizationName.replace(" ", "-") +
                  "_" +
                  DataStore.User.SpectrumId.replace(" ", "-") +
                  "_" +
                  DataStore.User.ProjectName.replace(" ", "-") +
                  ".sccm";
                _file.SaveFile(_fileName, DataStore.User);
              }}
            >
              Save...
            </div>
          );
          _items.push(
            <div
              key={"GetMenuChildren_" + index + _counter++}
              className="header_DialogLineItem"
              onClick={() => {
                let _file = new FileOperations();
                let _fileName =
                  DataStore.User.OrganizationName.replace(" ", "-") +
                  "_" +
                  DataStore.User.SpectrumId.replace(" ", "-") +
                  "_" +
                  DataStore.User.ProjectName.replace(" ", "-") +
                  ".sccm";

                let _data = DataStore.User;
                _data.PublishId = Utilities.Guid();
                _file.SaveFile(_fileName, _data);
              }}
            >
              Save with new IDs...
            </div>
          );
          _items.push(
            <div
              key={"whatever" + index}
              style={{
                display: "flex",
                minHeight: "3px",
                background: Utilities.rgbaAsString(112, 112, 112, 1)
              }}
            ></div>
          );
          _items.push(
            <div
              key={"GetMenuChildren_" + index + _counter++}
              className="header_DialogLineItem"
              onClick={() =>
                PopupContainer.ShowPopup("PUBLISH PROJECT", this.Publish())
              }
            >
              Publish...
            </div>
          );
        } else {
          _items.push(
            <div
              key={"GetMenuChildren_" + index + _counter++}
              className="header_DialogLineItem"
              style={{ color: Utilities.rgbaAsString(112, 112, 112, 1) }}
            >
              Save...
            </div>
          );
          _items.push(
            <div
              key={"GetMenuChildren_" + index + _counter++}
              className="header_DialogLineItem"
              style={{ color: Utilities.rgbaAsString(112, 112, 112, 1) }}
            >
              Save with new IDs...
            </div>
          );
          _items.push(
            <div
              key={"whatever" + index}
              style={{
                display: "flex",
                minHeight: "3px",
                background: Utilities.rgbaAsString(112, 112, 112, 1)
              }}
            ></div>
          );
          _items.push(
            <div
              key={"GetMenuChildren_" + index + _counter++}
              className="header_DialogLineItem"
              style={{ color: Utilities.rgbaAsString(112, 112, 112, 1) }}
            >
              Publish...
            </div>
          );
        }
        return _items;
      case 1:
        _items.push(
          <div
            key={"GetMenuChildren_" + index + _counter++}
            className="header_DialogLineItem"
            onClick={() => {
              PopupContainer.ShowPopup(
                "PROJECT PROPERTIES",
                <Preferences Refresh={this.RefreshHandler} />
              );
            }}
          >
            Properties
          </div>
        );
        _items.push(
          <div
            key={"whatever" + index}
            style={{
              display: "flex",
              minHeight: "3px",
              background: Utilities.rgbaAsString(112, 112, 112, 1)
            }}
          ></div>
        );
        _items.push(
          <div
            key={"GetMenuChildren_" + index + _counter++}
            className="header_DialogLineItem"
            onClick={() => {
              let _file = new FileOperations();
              _file.ImportProperties(this.RefreshHandler);
            }}
          >
            Import Properties...
          </div>
        );
        _items.push(
          <div
            key={"GetMenuChildren_" + index + _counter++}
            className="header_DialogLineItem"
            onClick={() => {
              let _file = new FileOperations();
              let _fileName =
                DataStore.User.OrganizationName.replace(" ", "-") +
                "_" +
                DataStore.User.SpectrumId.replace(" ", "-") +
                "_Properties.sccmpp";

              let _data = new DataTypes.UserData();
              _data.ProjectName = "New Project";
              _data.OrganizationName = DataStore.User.OrganizationName;
              _data.SpectrumId = DataStore.User.SpectrumId;
              _data.OrganizationLogo = DataStore.User.OrganizationLogo;
              _data.MemberManifestType = DataStore.User.MemberManifestType;
              _data.MemberManifestUrl = DataStore.User.MemberManifestUrl;
              _data.MemberManifestPath = DataStore.User.MemberManifestPath;

              _file.SaveFile(_fileName, _data);
            }}
          >
            Export Properties...
          </div>
        );
        return _items;
      case 2:
        if (DataStore.User.MemberList.length > 0) {
          _items.push(
            <div
              key={"GetMenuChildren_" + index + _counter++}
              className="header_DialogLineItem"
              onClick={() => {
                this.ViewMembers();
              }}
            >
              Members
            </div>
          );
        } else {
          _items.push(
            <div
              key={"GetMenuChildren_" + index + _counter++}
              className="header_DialogLineItem"
              style={{ color: Utilities.rgbaAsString(112, 112, 112, 1) }}
            >
              Members
            </div>
          );
        }
        _items.push(
          <div
            key={"GetMenuChildren_" + index + _counter++}
            className="header_DialogLineItem"
            onClick={() => {
              this.ViewMissions();
            }}
          >
            Missions
          </div>
        );
        _items.push(
          <div
            key={"whatever" + index}
            style={{
              display: "flex",
              minHeight: "3px",
              background: Utilities.rgbaAsString(112, 112, 112, 1)
            }}
          ></div>
        );
        _items.push(
          <div
            key={"GetMenuChildren_" + index + _counter++}
            className="header_DialogLineItem"
            onClick={() => {
              if (document.fullscreenElement) document.exitFullscreen();
              else document.documentElement.requestFullscreen();
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                width: "100%"
              }}
            >
              <div style={{ display: "flex" }}>Toggle Fullscreen</div>
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
              ></div>
            </div>
          </div>
        );
        return _items;
      case 3:
        _items.push(
          <div
            key={"GetMenuChildren_" + index + _counter++}
            className="header_DialogLineItem"
            onClick={() => {
              let _elem = document.createElement("a");
              _elem.href = DataStore.DOMAIN + "release_notes.txt";
              _elem.target = "_blank";
              _elem.click();
            }}
          >
            Release Notes
          </div>
        );
        _items.push(
          <div
            key={"GetMenuChildren_" + index + _counter++}
            className="header_DialogLineItem"
            onClick={() => {
              let _elem = document.createElement("a");
              _elem.href = "https://forms.gle/AYnJ6h6WUF38ydhy9";
              _elem.target = "_blank";
              _elem.click();
            }}
          >
            Report Bug
          </div>
        );
        _items.push(
          <div
            key={"GetMenuChildren_" + index + _counter++}
            className="header_DialogLineItem"
            onClick={() => {
              let _elem = document.createElement("a");
              _elem.href = DataStore.DOMAIN + "help.html";
              _elem.target = "_blank";
              _elem.click();
            }}
          >
            Help Document
          </div>
        );
        return _items;
      default:
        break;
    }
  }

  RenderMenuItems() {
    if (!DataStore.User.ReadOnly) {
      let _menu = [];
      for (let i = 0; i < this.MenuList.length; i++) {
        _menu.push(
          <div key={"RenderMenuItems_" + i} style={{ display: "inline-flex" }}>
            <div
              className="header_MenuItem"
              id={this.MenuList[i][2]}
              onClick={() => {
                this.ToggleMenu(i);
              }}
            >
              {this.MenuList[i][3]}
            </div>
            <div
              className="header_DialogContainer"
              id={this.MenuList[i][1]}
              onClick={() => {
                this.ToggleMenu(i);
              }}
            >
              {this.GetMenuChildren(i)}
            </div>
          </div>
        );
      }
      return _menu;
    }
  }

  CloseMemberListHandler() {
    this.setState({ MembersListVisible: false });
  }

  CloseMissionListHandler() {
    this.setState({ MissionListVisible: false });
  }

  RenderMembers() {
    let _memberManager = (
      <MemberManager
        Refresh={this.props.Refresh}
        Visible={this.state.MembersListVisible}
        Close={this.CloseMemberListHandler}
        View={this.ViewMembers}
      />
    );
    return _memberManager;
  }

  RenderMissions() {
    let _missionManager = (
      <MissionManager
        Refresh={this.props.Refresh}
        Visible={this.state.MissionListVisible}
        Close={this.CloseMissionListHandler}
        View={this.ViewMissions}
      />
    );
    return _missionManager;
  }

  render() {
    return (
      <div className="header">
        {this.RenderMembers()}
        {this.RenderMissions()}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            background: Utilities.rgbaAsString(88, 88, 96, 1),
            borderBottom: "solid 1px black"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              minHeight: "1.5vh",
              paddingLeft: "0.25vw"
            }}
          >
            {(DataStore.User.OrganizationName.length > 1
              ? DataStore.User.OrganizationName
              : "") +
              (DataStore.User.SpectrumId.length > 1
                ? " [" + DataStore.User.SpectrumId + "] "
                : "") +
              (DataStore.User.ProjectName.length > 1
                ? "(" + DataStore.User.ProjectName + ")"
                : "(UNTITLED)")}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            SCCM
          </div>
        </div>
        {this.RenderMenuItems()}
      </div>
    );
  }
}

export default Header;
