import React, { Component } from "react";
import powered from "../images/powered.svg";
import "../styles/Footer.css";

class Footer extends Component {
  OpenVector27() {
    window.open("https://vector27.sc-verse.com");
  }

  render() {
    return (
      <footer className="Footer">
        <div className="Footer-left" />
        <div className="Footer-center" />
        <div className="Footer-right">
          <img
            onClick={this.OpenVector27}
            src={powered}
            className="Footer-powered"
            alt="powered"
          />
        </div>
      </footer>
    );
  }
}

export default Footer;
