import React from "react";
import DataStore from "../scripts/DataStore.js";
import PopupContainer from "./PopupContainer.js";
import * as BranchManager from "./BranchManager.js";
import "../styles/PopupContainer.css";
import "../styles/CommandContainer.css";
import "../styles/DivisionContainer.css";

class CommandContainer extends React.Component {
  constructor(props) {
    super(props);
    this.RefreshHandler = this.RefreshHandler.bind(this);
  }

  RefreshHandler() {
    this.props.Refresh();
  }

  render() {
    return (
      <div id="commandContainer_Parent">
        <div id="commandContainer_Title">
          <div style={{ width: "100%" }}>
            {DataStore.User.OrganizationLogo.length > 1 ? (
              <img
                src={DataStore.User.OrganizationLogo}
                alt="org_logo"
                style={{ maxHeight: "2.5em", margin: ".33em" }}
              />
            ) : (
              "COMMAND"
            )}
          </div>
          {DataStore.User.ReadOnly ? (
            <div id="commandConatiner_AddBranch" />
          ) : (
            <div
              id="commandConatiner_AddBranch"
              onClick={() => {
                PopupContainer.ShowPopup(
                  "ADD COMMAND BRANCH",
                  <BranchManager.Add Refresh={this.RefreshHandler} />
                );
              }}
            >
              +
            </div>
          )}
        </div>
        <BranchManager.Print />
      </div>
    );
  }
}

export default CommandContainer;
