import React from "react";
import DataStore from "../scripts/DataStore.js";
import * as DataTypes from "../scripts/DataTypes.js";
import PopupContainer from "./PopupContainer.js";
import * as Utilities from "../scripts/Utilities";
import FileOperation from "./FileOperations.js";

class Preferences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ProjectName: DataStore.User.ProjectName,
      OrganizationName: DataStore.User.OrganizationName,
      SpecturmId: DataStore.User.SpectrumId,
      OrganizationLogo: DataStore.User.OrganizationLogo,
      MemberManifestUrl: DataStore.User.MemberManifestUrl,
      MemberManifestType: DataStore.User.MemberManifestType,
      ProjectNameUid: Utilities.Guid(),
      OrganizationNameUid: Utilities.Guid(),
      SpecturmIdUid: Utilities.Guid(),
      OrganizationLogoUid: Utilities.Guid(),
      MemberManifestUrlUid: Utilities.Guid(),
      MemberManifestPathUid: Utilities.Guid(),
      MemberManifestUrlCheckboxUid: Utilities.Guid(),
      MemberManifestPathCheckboxUid: Utilities.Guid(),
      BrowseElem: document.createElement("input")
    };

    this.TextChangeHandler = this.TextChangeHandler.bind(this);
    this.CheckboxChangeEventHandler = this.CheckboxChangeEventHandler.bind(
      this
    );
  }

  componentDidMount() {
    let _input = this.state.BrowseElem;
    _input.value = DataStore.User.MemberManifestPath;
    this.setState({ BrowseElem: _input });
  }

  TextChangeHandler(e) {
    switch (e.target.id) {
      case this.state.ProjectNameUid:
        this.setState({ ProjectName: e.target.value });
        break;
      case this.state.OrganizationNameUid:
        this.setState({ OrganizationName: e.target.value });
        break;
      case this.state.SpecturmIdUid:
        this.setState({ SpecturmId: e.target.value });
        break;
      case this.state.OrganizationLogoUid:
        this.setState({ OrganizationLogo: e.target.value });
        break;
      case this.state.MemberManifestUrlUid:
        this.setState({ MemberManifestUrl: e.target.value });
        break;
      default:
        break;
    }
  }

  CheckboxChangeEventHandler(e) {
    switch (e.target.id) {
      case this.state.MemberManifestUrlCheckboxUid:
        this.setState({
          MemberManifestType: DataTypes.eMemberManifestType.Url
        });
        break;
      case this.state.MemberManifestPathCheckboxUid:
        this.setState({
          MemberManifestType: DataTypes.eMemberManifestType.File
        });
        break;
      default:
        break;
    }
  }

  Browse() {
    let _file = this.state.BrowseElem;
    _file.type = "file";
    _file.accept = ".json";
    _file.name = "files[]";
    _file.onchange = () => {
      this.setState({
        BrowseElem: _file,
        MemberManifestType: DataTypes.eMemberManifestType.File
      });
      new FileOperation().UploadManifest(
        DataStore.User.Uid,
        _file,
        this.forceUpdate()
      );
    };
    _file.click();
  }

  Save() {
    let _error = "";

    if (this.state.ProjectName.length < 1) {
      _error += "\rProject Name is required.";
    }
    if (this.state.OrganizationName.length < 1) {
      _error += "\rOrganization Name is required.";
    }
    if (this.state.SpecturmId.length < 1) {
      _error += "\rSpectrum ID is required.";
    }

    if (_error.length > 1) {
      PopupContainer.ShowError(_error);
    } else {
      DataStore.User.ProjectName = this.state.ProjectName;
      DataStore.User.OrganizationName = this.state.OrganizationName;
      DataStore.User.SpectrumId = this.state.SpecturmId;
      DataStore.User.OrganizationLogo = this.state.OrganizationLogo;
      DataStore.User.MemberManifestUrl = this.state.MemberManifestUrl;
      DataStore.User.MemberManifestPath = this.state.BrowseElem.value;
      DataStore.User.MemberManifestType = this.state.MemberManifestType;
      PopupContainer.HidePopup();
      this.props.Refresh();
    }
  }

  render() {
    let _file = this.state.BrowseElem.value.split("\\");
    if (_file.length > 1) _file = _file[_file.length - 1];

    return (
      <div className="popupContainer_InputTable">
        <div className="pupupContainer_InputLabel">Project Name</div>
        <input
          className="pupupContainer_InputObject"
          type="text"
          id={this.state.ProjectNameUid}
          value={this.state.ProjectName}
          onChange={this.TextChangeHandler}
        />
        <div className="pupupContainer_InputLabel">Organization Name</div>
        <input
          className="pupupContainer_InputObject"
          type="text"
          id={this.state.OrganizationNameUid}
          value={this.state.OrganizationName}
          onChange={this.TextChangeHandler}
        />
        <div className="pupupContainer_InputLabel">Spectrum ID</div>
        <input
          className="pupupContainer_InputObject"
          type="text"
          id={this.state.SpecturmIdUid}
          value={this.state.SpecturmId}
          onChange={this.TextChangeHandler}
        />
        <div className="pupupContainer_InputLabel">Organization Logo</div>
        <input
          className="pupupContainer_InputObject"
          type="text"
          id={this.state.OrganizationLogoUid}
          value={this.state.OrganizationLogo}
          onChange={this.TextChangeHandler}
        />
        <div className="pupupContainer_InputLabel">Member Manifest</div>
        <div>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <div style={{ display: "inline-flex" }}>
              <input
                id={this.state.MemberManifestUrlCheckboxUid}
                style={{ marginTop: "0.3vh" }}
                type="checkbox"
                checked={this.state.MemberManifestType === 0}
                onChange={this.CheckboxChangeEventHandler}
              />
            </div>
            <div style={{ display: "inline", padding: "0 0.5vw 0 0.2vw" }}>
              URL
            </div>
            <input
              className="pupupContainer_InputObject"
              type="text"
              id={this.state.MemberManifestUrlUid}
              value={this.state.MemberManifestUrl}
              onChange={this.TextChangeHandler}
              style={{ width: "100%" }}
              disabled={this.state.MemberManifestType === 1}
            />
          </div>
          <div style={{ marginTop: "1vh" }}>
            <div style={{ display: "inline-flex" }}>
              <input
                id={this.state.MemberManifestPathCheckboxUid}
                style={{ marginTop: "0.3vh" }}
                type="checkbox"
                checked={this.state.MemberManifestType === 1}
                onChange={this.CheckboxChangeEventHandler}
              />
            </div>
            <div style={{ display: "inline-flex", padding: "0 0.5vw 0 0.2vw" }}>
              Upload
            </div>
            <input
              className="pupupContainer_InputObject"
              type="text"
              id={this.state.MemberManifestPathUid}
              value={_file}
              onChange={this.TextChangeHandler}
              style={{ display: "inline" }}
              disabled={this.state.MemberManifestType === 0}
            />
            <div style={{ display: "inline-flex", marginLeft: "0.5vw" }}>
              <div
                className="popupContainer_Button"
                style={{
                  maxWidth: "4vw",
                  maxHeight: "1.75vh",
                  fontSize: "0.8em",
                  margin: "0 0 0 0"
                }}
                onClick={() => {
                  this.Browse();
                }}
              >
                Browse...
              </div>
            </div>
          </div>
        </div>
        <div
          className="popupContainer_ButtonRow"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={DataStore.DOMAIN + "/help.html#member_manifest"}
          >
            &#9873; Format Help
          </a>
        </div>
        <div id="popupContainer_ErrorMessage" />
        <div className="popupContainer_ButtonRow">
          <div className="popupContainer_Button" onClick={() => this.Save()}>
            SAVE
          </div>
          <div
            className="popupContainer_Button"
            onClick={() => PopupContainer.HidePopup()}
          >
            CANCEL
          </div>
        </div>
      </div>
    );
  }
}

export default Preferences;
