import React from "react";
import ReactDOM from "react-dom";
import "../styles/PopupContainer.css";

class PopupContainer extends React.Component {
  static Visible = false;

  static ShowPopup(title, content) {
    document.getElementById("popupContainer_TitleValue").innerHTML = title;
    ReactDOM.render(content, document.getElementById("popupContainer_Content"));
    document.getElementById("popupContainer_Title").style.marginTop =
      window.pageYOffset + 200 + "px";
    document.getElementById("popupContainer_Parent").style.display = "block";
    document.getElementById("popupContainer_Mask").style.display = "block";
    this.Visible = true;
  }

  static HidePopup() {
    let _parent = document.getElementById("popupContainer_Parent");
    _parent.style.display = "none";
    document.getElementById("popupContainer_Mask").style.display = "none";
    ReactDOM.unmountComponentAtNode(
      document.getElementById("popupContainer_Content")
    );
    this.Visible = false;
  }

  static ShowError(message) {
    let _div = document.getElementById("popupContainer_ErrorMessage");
    _div.innerText = "ERROR: " + message;
    _div.style.background = "darkred";
  }

  static ClearError() {
    let _div = document.getElementById("popupContainer_ErrorMessage");
    _div.innerText = "";
    _div.style.background = "none";
  }

  static IsVisible() {
    return this.Visible;
  }

  render() {
    return (
      <div>
        <div id="popupContainer_Mask" />
        <div id="popupContainer_Parent">
          <div id="popupContainer_Title">
            <div id="popupContainer_TitleValue" />
            <div
              id="popupContainer_CloseBtn"
              onClick={() => {
                PopupContainer.HidePopup();
              }}
            >
              X
            </div>
          </div>
          <div id="popupContainer_Content" />
        </div>
      </div>
    );
  }
}

export default PopupContainer;
