import React from "react";
import DataStore from "./DataStore.js";
import CrewControls from "./CrewControls.js";
import PopupContainer from "./PopupContainer.js";
import * as Utilities from "./Utilities.js";
import "../styles/CrewContainer.css";
import RankOn from "../images/rank_on.svg";
import RankOff from "../images/rank_off.svg";

class Add extends React.Component {}

class Remove extends React.Component {
  render() {
    return (
      <div className="popupContainer_InputTable">
        <div className="popupContainer_ButtonRow">
          Are you sure you want to remove the '
          <b>{this.props.Squadron.Crew[this.props.CrewIndex].Name}</b>' from the
          '<b>{this.props.Squadron.Name}</b>' squadron?
        </div>
        <div className="popupContainer_ButtonRow">
          <div
            className="popupContainer_Button"
            onMouseDown={() => {
              Utilities.arrayRemoveElement(
                this.props.Squadron.Crew,
                this.props.CrewIndex
              );
              this.props.Refresh();
              PopupContainer.HidePopup();
            }}
          >
            YES
          </div>
          <div
            className="popupContainer_Button"
            onClick={() => {
              PopupContainer.HidePopup();
            }}
          >
            NO
          </div>
        </div>
      </div>
    );
  }
}

class Print extends React.Component {
  CrewContainerVerticalTree(index, squadron) {
    let _border = this.props.Branch.Colors[DataStore.Color_Border];
    let _end = index < squadron.Crew.length - 1 ? 7 : 3;

    let _style = {
      backgroundColor: Utilities.rgbaAsString(
        _border.R,
        _border.G,
        _border.B,
        _border.A
      ),
      gridColumnStart: 2,
      gridColumnEnd: 3,
      gridRowStart: 1,
      gridRowEnd: _end
    };

    return _style;
  }

  CrewContainerHorizontalTree(branch) {
    let _border = branch.Colors[DataStore.Color_Border];
    let _style = {
      backgroundColor: Utilities.rgbaAsString(
        _border.R,
        _border.G,
        _border.B,
        _border.A
      ),
      gridColumnStart: 2,
      gridColumnEnd: 4,
      gridRowStart: 3,
      gridRowEnd: 4
    };

    return _style;
  }

  CrewPersonnelContainerStyle(branch) {
    let _background = branch.Colors[DataStore.Color_Background];
    let _border = branch.Colors[DataStore.Color_Border];

    let _style = {
      color: Utilities.rgbaAsString(
        _background.R,
        _background.G,
        _background.B,
        _background.A
      ),
      backgroundColor: Utilities.rgbaAsString(
        _border.R,
        _border.G,
        _border.B,
        _border.A
      ),
      borderColor: Utilities.rgbaAsString(
        _border.R,
        _border.G,
        _border.B,
        _border.A
      )
    };
    return _style;
  }

  CrewPersonnelEntryStyle(branch) {
    let _background = branch.Colors[DataStore.Color_Background];
    let _foreground = branch.Colors[DataStore.Color_Foreground];

    let _background_rgba = Utilities.rgbaAsString(
      _background.R,
      _background.G,
      _background.B,
      _background.A
    );

    let _style = {
      color: Utilities.rgbaAsString(
        _foreground.R,
        _foreground.G,
        _foreground.B,
        _foreground.A
      ),
      background:
        "linear-gradient(270deg, " +
        "rgba(0, 0, 0, 0)" +
        " 10%, " +
        _background_rgba +
        " 100%)"
    };
    return _style;
  }

  RankGraphic(member) {
    let _list = [];
    for (let i = 5; i > 0; i--) {
      if (i <= member.Stars) {
        _list.push(
          <img
            key={"RankGraphic_" + member.Uid + "_" + i}
            alt=""
            src={RankOn}
            style={{ maxHeight: "0.6em" }}
          />
        );
      } else {
        _list.push(
          <img
            key={"RankGraphic_" + member.Uid + "_" + i}
            alt=""
            src={RankOff}
            style={{ maxHeight: "0.6em" }}
          />
        );
      }
    }
    return _list;
  }

  ParseRole(roles) {
    if (roles.length > 0) {
      if (roles.indexOf(";") > 0) {
        return roles.split(";")[0];
      } else return roles;
    } else return <div>&nbsp;</div>;
  }

  PopulateCrew(squadron) {
    let _crew = [];
    for (let i = 0; i < squadron.Crew.length; i++) {
      let _color =
        squadron.Crew[i].Type === "Member"
          ? Utilities.rgbaAsString(128, 224, 255, 1)
          : "yellow";
      _crew.push(
        <div key={"PopulateCrew_" + i} className="crewContainer_Parent">
          <div style={this.CrewContainerVerticalTree(i, squadron)} />
          <div style={this.CrewContainerHorizontalTree(this.props.Branch)} />
          <div
            className="crewContainer_Tile"
            style={{
              gridRowStart: 2,
              gridRowEnd: 6,
              gridColumnStart: 4,
              gridColumnEnd: 5
            }}
          >
            <div
              className="crewContainer_PersonnelEntryOuter"
              style={this.CrewPersonnelEntryStyle(this.props.Branch)}
            >
              <div style={{ minWidth: "0.75vw", fontSize: "0.6em" }}>
                {i + 3}
              </div>
              <div
                className="crewContainer_PersonnelEntryInner"
                style={this.CrewPersonnelContainerStyle(this.props.Branch)}
              >
                <div className="crewContainer_MemberCardAvatar">
                  <img
                    alt=""
                    style={{ maxHeight: "2.75vh", borderRadius: "8px" }}
                    src={
                      "https://robertsspaceindustries.com" +
                      squadron.Crew[i].Avatar
                    }
                    onError={e =>
                      (e.target.src =
                        "https://robertsspaceindustries.com/rsi/static/images/account/avatar_default_big.jpg")
                    }
                  />
                </div>
                <div style={{ gridRowStart: 1, gridRowEnd: 4 }}>
                  <div
                    className="memberManager_Handle"
                    style={{ color: _color }}
                  >
                    {squadron.Crew[i].Handle}
                  </div>
                  <div className="memberManager_Moniker">
                    {squadron.Crew[i].Moniker}
                  </div>
                </div>
                <div
                  style={{ gridColumnStart: 3, gridRowStart: 1, gridRowEnd: 4 }}
                >
                  <div
                    className="memberManager_Rank"
                    style={{ paddingTop: "0.15em" }}
                  >
                    {this.RankGraphic(squadron.Crew[i])}
                  </div>
                  <div
                    className="memberManager_Roles"
                    style={{ paddingTop: "0.25em" }}
                  >
                    {this.ParseRole(squadron.Crew[i].Roles)}
                  </div>
                </div>
                <CrewControls
                  Refresh={this.props.Refresh}
                  Squadron={squadron}
                  CrewIndex={i}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return _crew;
  }

  render() {
    return this.PopulateCrew(this.props.Squadron);
  }
}

export { Add, Remove, Print };
