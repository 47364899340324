import * as DataTypes from "./DataTypes.js";

class DataStore {
  static DOMAIN = "https://www.sccm.sc-verse.com/";

  static Color_Background = 0;
  static Color_Foreground = 1;
  static Color_Border = 2;

  static MouseDown = false;
  static MouseX_Down = 0;
  static MouseY_Down = 0;
  static ObjectX_StartPosition = 0;
  static ObjectY_StartPosition = 0;
  static SelectedMember = "";
  static MembersZOrder = 0;
  static MissionsZOrder = 0;
  static User = new DataTypes.UserData();
}

export default DataStore;
