import React from "react";
import DataStore from "./DataStore.js";
import * as Utilities from "./Utilities.js";
import "../styles/MissionManager.css";

class MissionManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      MoveMouseDown: false,
      ResizeMouseDown: false,
      ScrollMouseDown: false,
      MissionMouseDown: false,
      StartX: 0,
      StartY: 0,
      StartHeight: 550,
      MisisionList: [],
      ScrollMargin: 0,
      StartTopMargin: 0,
      Search: ""
    };

    this.MoveHandler = this.MoveHandler.bind(this);
    this.ResizeHandler = this.ResizeHandler.bind(this);
    this.ScrollHandler = this.ScrollHandler.bind(this);
    this.MouseMoveHandler = this.MouseMoveHandler.bind(this);
  }

  MoveHandler(e) {
    this.setState({
      MoveMouseDown: true,
      StartX:
        e.clientX - document.getElementById("missionManager_Parent").offsetLeft,
      StartY:
        e.clientY - document.getElementById("missionManager_Parent").offsetTop
    });
  }

  ResizeHandler(e) {
    this.setState({
      ResizeMouseDown: true,
      StartY: window.event.clientY,
      StartHeight: document.getElementById("missionManager_Parent").offsetHeight
    });
  }

  ScrollHandler(e) {
    this.setState({
      ScrollMouseDown: true,
      StartY: window.event.clientY,
      StartTopMargin:
        document.getElementById("missionManager_ScrollButton").offsetTop -
        document.getElementById("missionManager_ScrollContainer").offsetTop
    });
  }

  MouseMoveHandler(e) {
    let _container = document.getElementById("missionManager_Parent");
    if (this.state.MoveMouseDown) {
      _container.style.left = window.event.clientX - this.state.StartX + "px";
      _container.style.top = window.event.clientY - this.state.StartY + "px";
    } else if (this.state.ResizeMouseDown) {
      _container.style.width =
        window.event.clientX - _container.offsetLeft + 10 + "px";
      _container.style.height =
        this.state.StartHeight +
        window.event.clientY -
        this.state.StartY +
        8 +
        "px";
    } else if (this.state.ScrollMouseDown) {
      let _button = document.getElementById("missionManager_ScrollButton");
      let _outer = document.getElementById("missionManager_OuterContainer");
      let _inner = document.getElementById("missionManager_InnerContainer");
      let _scroll = document.getElementById("missionManager_ScrollContainer");

      let _variance =
        this.state.StartTopMargin + (window.event.clientY - this.state.StartY);
      let _direction = window.event.clientY - this.state.StartY;
      let _limit = _outer.offsetHeight - _button.offsetHeight;

      if (_direction < 0) {
        if (_variance >= 0) _button.style.marginTop = _variance + "px";
        else _button.style.marginTop = 0 + "px";
      } else {
        if (_variance < _limit) _button.style.marginTop = _variance + "px";
        else _button.style.marginTop = _limit - 3 + "px";
      }

      let _scale =
        parseInt(_button.style.marginTop.replace("px", "")) /
        (_scroll.offsetHeight - _button.offsetHeight - 3);

      let _position = (_inner.offsetHeight - _outer.offsetHeight) * _scale;
      this.setState({ ScrollMargin: -_position });
    } else {
      let _elem = document.getElementById("missionManager_FloatObject");
      if (this.state.MemberMouseDown) {
        _elem.style.left = window.event.clientX + 3 + "px";
        _elem.style.top = window.event.clientY + 3 + "px";
      } else {
        if (_elem !== null) document.body.removeChild(_elem);
      }
    }
  }

  MissionClickHandler(e) {
    let _target = e.target.parentElement.parentElement;
    if (_target.className === "memberManager_CrewInnerContainer")
      _target = _target.parentElement;
    DataStore.SelectedMember = _target.id;
    let _member = Utilities.getArrayElementByUid(
      this.state.MemberList,
      _target.id
    );

    if (_member !== undefined) {
      let _color =
        _member.Type === "Member"
          ? Utilities.rgbaAsString(128, 224, 255, 1)
          : "yellow";

      let _outer = document.createElement("div");
      _outer.className = "memberManager_CrewFloatContainer";
      _outer.id = "memberManager_FloatObject";
      _outer.style.left = window.event.clientX + "px";
      _outer.style.top = window.event.clientY + "px";
      _outer.style.minWidth = "14vw";
      _outer.style.background = Utilities.rgbaAsString(32, 32, 32, 1);

      let _inner = document.createElement("div");
      _inner.className = "memberManager_CrewInnerContainer";
      _outer.appendChild(_inner);

      let _avatar = document.createElement("img");
      _avatar.className = "memberManager_Avatar";
      _avatar.src = "https://robertsspaceindustries.com" + _member.Avatar;

      let _handle = document.createElement("div");
      _handle.className = "memberManager_Handle";
      _handle.innerHTML = _member.Handle;
      _handle.style = "color: " + _color;

      let _moniker = document.createElement("div");
      _moniker.className = "memberManager_Moniker";
      _moniker.innerHTML = _member.Moniker;

      let _rank = document.createElement("div");
      _rank.className = "memberManager_Rank";
      this.RankGraphic(_member).map(x => {
        _rank.innerHTML +=
          "<img style='max-height: " +
          x.props.style.maxHeight +
          ";' src='" +
          x.props.src +
          "'/>";
        return true;
      });

      let _roles = document.createElement("div");
      _roles.className = "memberManager_Roles";
      _roles.innerHTML = this.ParseRole(_member.Roles);

      _inner.appendChild(_avatar);
      _inner.appendChild(_handle);
      _inner.appendChild(_moniker);
      _inner.appendChild(_rank);
      _inner.appendChild(_roles);

      document.body.appendChild(_outer);
      this.setState({ MemberMouseDown: true });
    }
  }

  SearchChangeHandler(e) {
    this.setState({ ScrollMargin: 0, Search: e.target.value });
  }

  componentDidMount() {
    document.getElementById(
      "missionManager_Parent"
    ).style.height = document.getElementById(
      "missionManager_Parent"
    ).offsetHeight;
  }

  componentWillUpdate() {
    window.onmousemove = this.MouseMoveHandler;
    window.onmouseup = () => {
      this.setState({
        MoveMouseDown: false,
        ResizeMouseDown: false,
        ScrollMouseDown: false,
        MissionMouseDown: false
      });
      DataStore.SelectedMission = "";
    };
    window.onmousedown = () => {
      DataStore.MissionsZOrder = DataStore.MembersZOrder + 1;
      this.forceUpdate();
    };
  }

  componentDidUpdate() {
    let _button = document.getElementById("missionManager_ScrollButton");
    let _scroll = document.getElementById("missionManager_ScrollContainer");
    let _inner = document.getElementById("missionManager_InnerContainer");
    let _outer = document.getElementById("missionManager_OuterContainer");

    let _range = _inner.offsetHeight - _outer.offsetHeight;
    let _scale = -this.state.ScrollMargin / _range;
    let _position = _scale * (_scroll.offsetHeight - _button.offsetHeight - 3);

    _button.style.marginTop = _position + "px";
  }

  RenderOuterContainer() {
    let _offset = 36;
    return (
      <div id="missionManager_OuterContainer">
        <div
          id="missionManager_InnerContainer"
          onWheel={e => {
            if (e.deltaY > 0) {
              this.setState({
                ScrollMargin:
                  this.state.ScrollMargin - _offset >
                  -(
                    document.getElementById("missionManager_InnerContainer")
                      .offsetHeight -
                    document.getElementById("missionManager_OuterContainer")
                      .offsetHeight
                  )
                    ? this.state.ScrollMargin - _offset
                    : -(
                        document.getElementById("missionManager_InnerContainer")
                          .offsetHeight -
                        document.getElementById("missionManager_OuterContainer")
                          .offsetHeight
                      )
              });
            } else if (e.deltaY < 0)
              this.setState({
                ScrollMargin:
                  this.state.ScrollMargin + _offset < 0
                    ? this.state.ScrollMargin + _offset
                    : 0
              });
          }}
          style={{ marginTop: this.state.ScrollMargin }}
        >
          <div style={{ gridColumnStart: 1 }}>{/*this.ListMembers()*/}</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        id="missionManager_Parent"
        onMouseOver={() => (document.body.style.overflow = "hidden")}
        onMouseOut={() => (document.body.style.overflow = "auto")}
        onLoad={() =>
          this.setState({
            StartHeight: document.getElementById("missionManager_Parent")
              .offsetHeight
          })
        }
        style={
          this.props.Visible
            ? this.state.StartHeight === 550
              ? {
                  display: "block",
                  height: "550px",
                  zIndex: DataStore.MissionsZOrder
                }
              : { display: "block", zIndex: DataStore.MissionsZOrder }
            : { display: "none" }
        }
      >
        <div id="missionManager_Title" onMouseDown={this.MoveHandler}>
          <div id="missionManager_TitleValue">MISSION LIST</div>
          <div id="missionManager_CloseBtn" onClick={() => this.props.Close()}>
            X
          </div>
        </div>
        <div id="missionManager_Content">
          <input
            type="text"
            className="missionManager_InputObject"
            onChange={this.SearchChangeHandler}
          />
          {this.RenderOuterContainer()}
          <div id="missionManager_ScrollContainer">
            <div
              id="missionManager_ScrollButton"
              onMouseDown={this.ScrollHandler}
            />
          </div>
        </div>
        <div id="missionManager_Footer">
          <div
            id="missionManager_ResizeButton"
            onMouseDown={this.ResizeHandler}
          >
            &#8690;
          </div>
        </div>
      </div>
    );
  }
}

export default MissionManager;
