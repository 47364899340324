export function Guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function rgbaAsString(r, g, b, a) {
  return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")";
}

export function arrayMove(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export function arrayRemoveElement(arr, index) {
  if (index > -1) {
    arr.splice(index, 1);
  }
}

export function download(filename, content) {
  let _elem = document.createElement("a");
  _elem.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(content)
  );
  _elem.setAttribute("download", filename);

  if (document.createEvent) {
    let _event = document.createEvent("MouseEvents");
    _event.initEvent("click", true, true);
    _elem.dispatchEvent(_event);
  } else {
    _elem.click();
  }
}

export function getArrayElementByUid(array, uid) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].Uid === uid) return array[i];
  }
}

export function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const bandA = a.Handle.toUpperCase();
  const bandB = b.Handle.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

export function write(filename, content) {}
