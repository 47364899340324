import * as DataTypes from "./DataTypes.js";

class BranchTemplates {
  static Unset() {
    return new DataTypes.BranchData("", [
      { R: 0, G: 0, B: 0, A: (1).toFixed(2) },
      { R: 0, G: 0, B: 0, A: (1).toFixed(2) },
      { R: 0, G: 0, B: 0, A: (1).toFixed(2) }
    ]);
  }

  static Security() {
    return new DataTypes.BranchData(
      "Security",

      [
        { R: 255, G: 48, B: 48, A: (1).toFixed(2) },
        { R: 140, G: 0, B: 0, A: (0.75).toFixed(2) },
        { R: 140, G: 0, B: 0, A: (1).toFixed(2) }
      ]
    );
  }

  static Science() {
    return new DataTypes.BranchData("Science", [
      { R: 148, G: 255, B: 148, A: (1).toFixed(2) },
      { R: 36, G: 140, B: 0, A: (0.75).toFixed(2) },
      { R: 36, G: 140, B: 0, A: (1).toFixed(2) }
    ]);
  }

  static Exploration() {
    return new DataTypes.BranchData(
      "Exploration",

      [
        { R: 255, G: 156, B: 255, A: (1).toFixed(2) },
        { R: 128, G: 0, B: 180, A: (0.75).toFixed(2) },
        { R: 128, G: 0, B: 180, A: (1).toFixed(2) }
      ]
    );
  }

  static Sourcing() {
    return new DataTypes.BranchData(
      "Sourcing",

      [
        { R: 156, G: 228, B: 255, A: (1).toFixed(2) },
        { R: 0, G: 112, B: 180, A: (0.75).toFixed(2) },
        { R: 0, G: 112, B: 180, A: (1).toFixed(2) }
      ]
    );
  }

  static Engineering() {
    return new DataTypes.BranchData(
      "Engineering",

      [
        { R: 255, G: 164, B: 80, A: (1).toFixed(2) },
        { R: 180, G: 88, B: 0, A: (0.75).toFixed(2) },
        { R: 180, G: 88, B: 0, A: (1).toFixed(2) }
      ]
    );
  }
}

export default BranchTemplates;
