import React from "react";
import DataStore from "./DataStore.js";
import PopupContainer from "./PopupContainer.js";
import * as Utilities from "./Utilities.js";
import * as BranchManager from "./BranchManager.js";
import * as DivisionManager from "./DivisionManager.js";

import "../styles/BranchControls.css";

class BranchContols extends React.Component {
  ControlButtonMouseOverHandler(event) {
    let _elem = event.target;
    let _color = _elem.parentElement.parentElement.style.color;
    _elem.style.border = "1px solid " + _color;
  }

  ControlButtonMouseLeaveHandler(event) {
    let _elem = event.target;
    _elem.style.border = "1px solid rgba(0, 0, 0, 0)";
  }

  MoveBackButton(index) {
    if (index > 0)
      return (
        <div
          className="branchControl_Button"
          onMouseOver={this.ControlButtonMouseOverHandler}
          onMouseLeave={this.ControlButtonMouseLeaveHandler}
          onClick={() => {
            Utilities.arrayMove(DataStore.User.Branches, index, index - 1);
            this.props.Refresh();
          }}
        >
          &lt;
        </div>
      );
  }

  MoveForwardButton(index) {
    if (index !== DataStore.User.Branches.length - 1)
      return (
        <div
          className="branchControl_Button"
          onMouseOver={this.ControlButtonMouseOverHandler}
          onMouseLeave={this.ControlButtonMouseLeaveHandler}
          onClick={() => {
            Utilities.arrayMove(DataStore.User.Branches, index, index + 1);
            this.props.Refresh();
          }}
        >
          &gt;
        </div>
      );
  }

  EditButton(index) {
    return (
      <div
        className="branchControl_Button"
        onMouseOver={this.ControlButtonMouseOverHandler}
        onMouseLeave={this.ControlButtonMouseLeaveHandler}
        onClick={() => {
          PopupContainer.ShowPopup(
            "EDIT COMMAND BRANCH",
            <BranchManager.Edit Refresh={this.props.Refresh} Index={index} />
          );
        }}
      >
        ✎
      </div>
    );
  }

  RemoveButton(index) {
    return (
      <div
        className="branchControl_Button"
        onMouseOver={this.ControlButtonMouseOverHandler}
        onMouseLeave={this.ControlButtonMouseLeaveHandler}
        onClick={() => {
          PopupContainer.ShowPopup(
            "REMOVE COMMAND BRANCH",
            <BranchManager.Remove Refresh={this.props.Refresh} Index={index} />
          );
        }}
      >
        -
      </div>
    );
  }

  AddDivision(index) {
    return (
      <div
        className="branchControl_Button"
        onMouseOver={this.ControlButtonMouseOverHandler}
        onMouseLeave={this.ControlButtonMouseLeaveHandler}
        onClick={() => {
          PopupContainer.ShowPopup(
            "ADD DIVISION",
            <DivisionManager.Add Refresh={this.props.Refresh} Index={index} />
          );
        }}
      >
        +
      </div>
    );
  }

  render() {
    return (
      <div className="branchControl_Container">
        {this.MoveBackButton(this.props.Index)}
        {this.MoveForwardButton(this.props.Index)}
        {this.EditButton(this.props.Index)}
        {this.RemoveButton(this.props.Index)}
        {this.AddDivision(this.props.Index)}
      </div>
    );
  }
}

export default BranchContols;
