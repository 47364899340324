import React from "react";
import * as Utilities from "./Utilities.js";
import * as CrewManger from "./CrewManager.js";
import "../styles/CrewContainer.css";
import PopupContainer from "./PopupContainer.js";
import DataStore from "./DataStore.js";

class CrewControls extends React.Component {
  MoveUp(index) {
    if (index > 0) {
      return !DataStore.User.ReadOnly ? (
        <div
          className="crewContainer_MemberCardControl"
          style={{
            gridRowStart: 1,
            gridColumnStart: 4,
            marginRight: "1.1em"
          }}
          onClick={() => {
            Utilities.arrayMove(this.props.Squadron.Crew, index, index - 1);
            this.props.Refresh();
          }}
        >
          <div style={{ minWidth: "1em", textAlign: "center" }}>&#8896;</div>
        </div>
      ) : (
        <div
          className="crewContainer_MemberCardControl"
          style={{
            gridRowStart: 1,
            gridColumnStart: 4,
            marginRight: "0.15em"
          }}
        >
          <div style={{ minWidth: "1em", textAlign: "center" }}>&nbsp;</div>
        </div>
      );
    } else return <div>&nbsp;</div>;
  }

  MoveDown(index) {
    if (index !== this.props.Squadron.Crew.length - 1)
      return !DataStore.User.ReadOnly ? (
        <div
          className="crewContainer_MemberCardControl"
          style={{
            gridRowStart: 3,
            gridColumnStart: 4,
            marginRight: "1.1em"
          }}
          onClick={() => {
            Utilities.arrayMove(this.props.Squadron.Crew, index, index + 1);
            this.props.Refresh();
          }}
        >
          <div style={{ minWidth: "1em", textAlign: "center" }}>&#8897;</div>
        </div>
      ) : (
        <div
          className="crewContainer_MemberCardControl"
          style={{
            gridRowStart: 3,
            gridColumnStart: 4,
            marginRight: "0.15em"
          }}
        >
          <div style={{ minWidth: "1em", textAlign: "center" }}>&nbsp;</div>
        </div>
      );
    else return <div>&nbsp;</div>;
  }

  Remove(index) {
    return !DataStore.User.ReadOnly ? (
      <div
        className="crewContainer_MemberCardControl"
        style={{
          gridRowStart: 2,
          gridColumnStart: 4,
          marginRight: "1.1em"
        }}
        onClick={() => {
          PopupContainer.ShowPopup(
            "REMOVE CREW MEMBER",
            <CrewManger.Remove
              Refresh={this.props.Refresh}
              Squadron={this.props.Squadron}
              CrewIndex={index}
            />
          );
        }}
      >
        <div style={{ minWidth: "1em", textAlign: "center" }}>-</div>
      </div>
    ) : (
      <div
        className="crewContainer_MemberCardControl"
        style={{
          gridRowStart: 2,
          gridColumnStart: 4,
          marginRight: "0.15em"
        }}
      >
        <div style={{ minWidth: "1em", textAlign: "center" }}>&nbsp;</div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ gridRowStart: 1, gridRowEnd: 4 }}>
        {this.MoveUp(this.props.CrewIndex)}
        {this.Remove(this.props.CrewIndex)}
        {this.MoveDown(this.props.CrewIndex)}
      </div>
    );
  }
}

export default CrewControls;
