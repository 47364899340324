import React from "react";
import "../styles/ColorPicker.css";
import DataStore from "./DataStore.js";
import * as Utilities from "./Utilities.js";
import PopupContainer from "./PopupContainer";

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Preview_Uid: Utilities.Guid(),
      R_Input_Uid: Utilities.Guid(),
      G_Input_Uid: Utilities.Guid(),
      B_Input_Uid: Utilities.Guid(),
      A_Input_Uid: Utilities.Guid(),
      R_Btn_Uid: Utilities.Guid(),
      G_Btn_Uid: Utilities.Guid(),
      B_Btn_Uid: Utilities.Guid(),
      A_Btn_Uid: Utilities.Guid(),
      R_Slider_Uid: Utilities.Guid(),
      G_Slider_Uid: Utilities.Guid(),
      B_Slider_Uid: Utilities.Guid(),
      A_Slider_Uid: Utilities.Guid()
    };

    this.onRgbChangeHandler = this.onRgbChangeHandler.bind(this);
    this.onAlphaChangeHandler = this.onAlphaChangeHandler.bind(this);
  }

  ActiveControls = {
    Btn: 0,
    Slider: 0,
    Input: 0,
    Channel: 0
  };

  SliderButtonClick(btn, slider, input, channel) {
    DataStore.MouseDown = true;
    DataStore.MouseX_Down = window.event.clientX;
    DataStore.ObjectX_StartPosition = document.getElementById(btn).offsetLeft;
    this.ActiveControls.Btn = btn;
    this.ActiveControls.Slider = slider;
    this.ActiveControls.Input = input;
    this.ActiveControls.Channel = channel;
  }

  SetPreviewColor() {
    var _color =
      "rgba(" +
      this.props.Color.R +
      ", " +
      this.props.Color.G +
      ", " +
      this.props.Color.B +
      ", " +
      this.props.Color.A +
      ")";

    document.getElementById(
      this.state.Preview_Uid
    ).style.backgroundColor = _color;
  }

  SliderButtonMove() {
    if (DataStore.MouseDown) {
      DataStore.MouseDown = window.event.buttons !== 0;
      var _variance = window.event.clientX - DataStore.MouseX_Down;
      var _btn = document.getElementById(this.ActiveControls.Btn);
      var _slider = document.getElementById(this.ActiveControls.Slider);
      var _input = document.getElementById(this.ActiveControls.Input);
      var _positionX = DataStore.ObjectX_StartPosition + _variance;

      if (
        _positionX >= _slider.offsetLeft &&
        _positionX <=
          _slider.offsetLeft + _slider.offsetWidth - _btn.offsetWidth + 1
      ) {
        _btn.style.left = _positionX + "px";

        var _value = 0;
        switch (this.ActiveControls.Channel) {
          case "A":
            _value = (
              (_btn.offsetLeft - _slider.offsetLeft) /
              (_slider.offsetWidth - _btn.offsetWidth)
            ).toFixed(2);
            break;
          default:
            _value = Math.round(
              ((_btn.offsetLeft - _slider.offsetLeft) /
                (_slider.offsetWidth - _btn.offsetWidth)) *
                254
            );
            break;
        }

        switch (this.ActiveControls.Channel) {
          case "R":
            _input.value = this.props.Color.R = _value;
            break;
          case "G":
            _input.value = this.props.Color.G = _value;
            break;
          case "B":
            _input.value = this.props.Color.B = _value;
            break;
          case "A":
            _input.value = this.props.Color.A = _value;
            break;
          default:
            break;
        }

        this.SetPreviewColor();
      }
    }
  }

  SliderButtonLeave() {
    DataStore.MouseDown = false;
  }

  SliderControl(btn, slider, input, channel) {
    var _style = {};
    switch (channel) {
      case "R":
        _style = {
          background:
            "linear-gradient(320deg, rgba(255, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 110%)"
        };
        break;
      case "G":
        _style = {
          background:
            "linear-gradient(320deg, rgba(0, 255, 0, 1) 0%, rgba(0, 0, 0, 1) 110%)"
        };
        break;
      case "B":
        _style = {
          background:
            "linear-gradient(320deg, rgba(0, 0, 255, 1) 0%, rgba(0, 0, 0, 1) 110%)"
        };
        break;
      case "A":
        _style = {
          background:
            "linear-gradient(320deg, rgba(255, 255, 255, 1) 0%, rgba(212, 212, 212, 0) 95%)"
        };
        break;
      default:
        break;
    }

    return (
      <div className="colorPicker_SliderBackground" id={slider} style={_style}>
        <div
          className="colorPicker_SliderButton"
          id={btn}
          onMouseDown={() => {
            this.SliderButtonClick(btn, slider, input, channel);
          }}
          onMouseUp={() => this.SliderButtonLeave()}
        />
      </div>
    );
  }

  SetSliderPosition(btn, slider, channel, value) {
    let _btn = document.getElementById(btn);
    let _slider = document.getElementById(slider);
    let _range = _slider.offsetWidth - _btn.offsetWidth;
    let _position = 0;

    switch (channel) {
      case "A":
        _position = value * _range;
        _btn.style.left = _slider.offsetLeft + _position + 1 + "px";
        break;
      default:
        _range = (_slider.offsetWidth - _btn.offsetWidth) / 255;
        _position = _range * value;
        _btn.style.left = _slider.offsetLeft + _position + 1 + "px";
        break;
    }
    this.SetPreviewColor();
  }

  InitializeSliders() {
    if (PopupContainer.IsVisible()) {
      this.SetSliderPosition(
        this.state.R_Btn_Uid,
        this.state.R_Slider_Uid,
        "R",
        this.props.Color.R
      );
      this.SetSliderPosition(
        this.state.G_Btn_Uid,
        this.state.G_Slider_Uid,
        "G",
        this.props.Color.G
      );
      this.SetSliderPosition(
        this.state.B_Btn_Uid,
        this.state.B_Slider_Uid,
        "B",
        this.props.Color.B
      );
      this.SetSliderPosition(
        this.state.A_Btn_Uid,
        this.state.A_Slider_Uid,
        "A",
        this.props.Color.A
      );
    }
  }

  componentDidUpdate() {
    this.InitializeSliders();
  }

  componentDidMount() {
    this.forceUpdate();
    window.addEventListener("resize", this.InitializeSliders.bind(this));
  }

  onAlphaChangeHandler(e) {
    if (e.target.value.length > 0) {
      if (Utilities.isNumeric(e.target.value) || e.target.value === ".") {
        if (e.target.value === ".") e.target.value = "0.";
        else if (e.target.value < 1 && e.target.value.indexOf(".") === 1) {
          if (e.target.value.length > 4)
            e.target.value = e.target.value.slice(0, 4);
        } else if (e.target.value > 1) e.target.value = (1).toFixed(2);
      } else
        e.target.value = e.target.value.slice(0, e.target.value.length - 1);
    }

    this.props.Color.A = e.target.value;
    this.forceUpdate();
  }

  onRgbChangeHandler(e) {
    if (e.target.value.length > 3) {
      e.target.value = e.target.value.slice(0, 3);
    } else if (e.target.value.length > 0)
      if (Utilities.isNumeric(e.target.value)) {
        if (e.target.value > 255) e.target.value = 255;
      } else {
        switch (e.target.id) {
          case this.state.R_Input_Uid:
            e.target.value = this.props.Color.R;
            break;
          case this.state.G_Input_Uid:
            e.target.value = this.props.Color.G;
            break;
          case this.state.B_Input_Uid:
            e.target.value = this.props.Color.B;
            break;
          default:
            break;
        }
      }

    switch (e.target.id) {
      case this.state.R_Input_Uid:
        this.props.Color.R = e.target.value;
        break;
      case this.state.G_Input_Uid:
        this.props.Color.G = e.target.value;
        break;
      case this.state.B_Input_Uid:
        this.props.Color.B = e.target.value;
        break;
      default:
        break;
    }
    this.forceUpdate();
  }

  render() {
    return (
      <div
        className="colorPicker_Parent"
        onMouseMove={() => {
          this.SliderButtonMove();
        }}
      >
        <div className="colorPicker_Preview" id={this.state.Preview_Uid} />
        <div className="colorPicker_R">
          {this.SliderControl(
            this.state.R_Btn_Uid,
            this.state.R_Slider_Uid,
            this.state.R_Input_Uid,
            "R"
          )}
        </div>
        <div className="colorPicker_G">
          {this.SliderControl(
            this.state.G_Btn_Uid,
            this.state.G_Slider_Uid,
            this.state.G_Input_Uid,
            "G"
          )}
        </div>
        <div className="colorPicker_B">
          {this.SliderControl(
            this.state.B_Btn_Uid,
            this.state.B_Slider_Uid,
            this.state.B_Input_Uid,
            "B"
          )}
        </div>
        <div className="colorPicker_A">
          {this.SliderControl(
            this.state.A_Btn_Uid,
            this.state.A_Slider_Uid,
            this.state.A_Input_Uid,
            "A"
          )}
        </div>
        <input
          className="colorPicker_R_Input"
          style={{ border: "rgba(0, 0, 0, 1) 1px solid" }}
          type="text"
          id={this.state.R_Input_Uid}
          value={this.props.Color.R}
          onChange={this.onRgbChangeHandler}
        />
        <input
          className="colorPicker_G_Input"
          style={{ border: "rgba(0, 0, 0, 1) 1px solid" }}
          type="text"
          id={this.state.G_Input_Uid}
          value={this.props.Color.G}
          onChange={this.onRgbChangeHandler}
        />
        <input
          className="colorPicker_B_Input"
          style={{ border: "rgba(0, 0, 0, 1) 1px solid" }}
          type="text"
          id={this.state.B_Input_Uid}
          value={this.props.Color.B}
          onChange={this.onRgbChangeHandler}
        />
        <input
          className="colorPicker_A_Input"
          style={{ border: "rgba(0, 0, 0, 1) 1px solid" }}
          type="text"
          id={this.state.A_Input_Uid}
          value={this.props.Color.A}
          onChange={this.onAlphaChangeHandler}
        />
      </div>
    );
  }
}

export default ColorPicker;
