import React from "react";
import PopupContainer from "./PopupContainer.js";
import * as Utilities from "./Utilities.js";
import * as DivisionManager from "./DivisionManager.js";
import * as SquadronManager from "./SquadronManager.js";
import "../styles/DivisionControls.css";

class DivisionContols extends React.Component {
  ControlButtonMouseOverHandler(event) {
    let _elem = event.target;
    let _color = _elem.parentElement.parentElement.style.color;
    _elem.style.border = "1px solid " + _color;
  }

  ControlButtonMouseLeaveHandler(event) {
    let _elem = event.target;
    _elem.style.border = "1px solid rgba(0, 0, 0, 0)";
  }

  MoveBackButton(index) {
    if (index > 0)
      return (
        <div
          className="divisionControl_Button"
          onMouseOver={this.ControlButtonMouseOverHandler}
          onMouseLeave={this.ControlButtonMouseLeaveHandler}
          onClick={() => {
            Utilities.arrayMove(this.props.Branch.Divisions, index, index - 1);
            this.props.Refresh();
          }}
        >
          &#8896;
        </div>
      );
  }

  MoveForwardButton(index) {
    if (index !== this.props.Branch.Divisions.length - 1)
      return (
        <div
          className="divisionControl_Button"
          onMouseOver={this.ControlButtonMouseOverHandler}
          onMouseLeave={this.ControlButtonMouseLeaveHandler}
          onClick={() => {
            Utilities.arrayMove(this.props.Branch.Divisions, index, index + 1);
            this.props.Refresh();
          }}
        >
          &#8897;
        </div>
      );
  }

  EditButton(index) {
    return (
      <div
        className="divisionControl_Button"
        onMouseOver={this.ControlButtonMouseOverHandler}
        onMouseLeave={this.ControlButtonMouseLeaveHandler}
        onClick={() => {
          PopupContainer.ShowPopup(
            "EDIT DIVISION",
            <DivisionManager.Edit
              Refresh={this.props.Refresh}
              Branch={this.props.Branch}
              DivisionIndex={index}
            />
          );
        }}
      >
        ✎
      </div>
    );
  }

  RemoveButton(index) {
    return (
      <div
        className="divisionControl_Button"
        onMouseOver={this.ControlButtonMouseOverHandler}
        onMouseLeave={this.ControlButtonMouseLeaveHandler}
        onClick={() => {
          PopupContainer.ShowPopup(
            "REMOVE DIVISION",
            <DivisionManager.Remove
              Refresh={this.props.Refresh}
              Branch={this.props.Branch}
              DivisionIndex={index}
            />
          );
        }}
      >
        -
      </div>
    );
  }

  AddDivision(index) {
    return (
      <div
        className="divisionControl_Button"
        onMouseOver={this.ControlButtonMouseOverHandler}
        onMouseLeave={this.ControlButtonMouseLeaveHandler}
        onClick={() => {
          PopupContainer.ShowPopup(
            "ADD SQUADRON",
            <SquadronManager.Add
              Refresh={this.props.Refresh}
              Division={this.props.Branch.Divisions[index]}
            />
          );
        }}
      >
        +
      </div>
    );
  }

  render() {
    return (
      <div className="divisionControl_Container">
        {this.MoveBackButton(this.props.DivisionIndex)}
        {this.MoveForwardButton(this.props.DivisionIndex)}
        {this.EditButton(this.props.DivisionIndex)}
        {this.RemoveButton(this.props.DivisionIndex)}
        {this.AddDivision(this.props.DivisionIndex)}
      </div>
    );
  }
}

export default DivisionContols;
