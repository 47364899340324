import React from "react";
import DataStore from "./DataStore.js";
import PopupContainer from "./PopupContainer.js";
import SquadronControls from "./SquadronControls.js";
import * as Utilities from "./Utilities.js";
import * as DataTypes from "./DataTypes.js";
import * as CrewManager from "./CrewManager.js";
import "../styles/SquadronContainer.css";
import RankOn from "../images/rank_on.svg";
import RankOff from "../images/rank_off.svg";

class Add extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Name_Uid: Utilities.Guid(),
      Comments_Uid: Utilities.Guid(),
      Name: "",
      CommentsValue: ""
    };
    this.NameChangeHandler = this.NameChangeHandler.bind(this);
    this.CommentChangeHandler = this.CommentChangeHandler.bind(this);
    this.KeyDownHandler = this.KeyDownHandler.bind(this);
  }

  NameChangeHandler(e) {
    this.setState({ Name: e.target.value });
  }

  CommentChangeHandler(e) {
    this.setState({ CommentsValue: e.target.value });
  }

  Commit(name, comments) {
    if (name.length === 0) PopupContainer.ShowError("Name field is required.");
    else {
      let _division = this.props.Division;
      let _squadron = new DataTypes.SquadronData(name);
      _squadron.Comments = comments;
      _division.Squadrons.push(_squadron);
      this.props.Refresh();
      PopupContainer.HidePopup();
    }
  }

  KeyDownHandler(e) {
    if (e.keyCode === 13)
      this.Commit(this.state.Name, this.state.CommentsValue);
    else if (e.keyCode === 27) PopupContainer.HidePopup();
  }

  componentDidMount() {
    let _elem = document.getElementById(this.state.Name_Uid);
    window.setTimeout(() => {
      _elem.focus();
      _elem.select();
    }, 1);
  }

  render() {
    return (
      <div className="popupContainer_InputTable">
        <div className="popupContainer_ButtonRow">
          <p style={{ marginBottom: "2vh" }}>
            Provide a squadron name for the '<b>{this.props.Division.Name}</b>'
            division.
          </p>
        </div>
        <div className="pupupContainer_InputLabel">Name</div>
        <input
          className="pupupContainer_InputObject"
          type="text"
          style={{
            border: "rgba(0, 0, 0, 1) 1px solid"
          }}
          id={this.state.Name_Uid}
          onChange={this.NameChangeHandler}
          value={this.state.Name}
          onKeyDown={this.KeyDownHandler}
        />
        <div className="pupupContainer_InputLabel">Comments</div>
        <textarea
          className="pupupContainer_InputObject"
          rows="12"
          style={{
            border: "rgba(0, 0, 0, 1) 1px solid",
            minWidth: "15vw",
            resize: "none"
          }}
          id={this.state.Comments_Uid}
          onChange={this.CommentChangeHandler}
          value={this.state.CommentsValue}
          onKeyDown={this.KeyDownHandler}
        />
        <div id="popupContainer_ErrorMessage" />
        <div className="popupContainer_ButtonRow">
          <div
            className="popupContainer_Button"
            onMouseDown={() => {
              this.Commit(this.state.Name, this.state.CommentsValue);
            }}
          >
            ADD
          </div>
          <div
            className="popupContainer_Button"
            onClick={() => {
              PopupContainer.HidePopup();
            }}
          >
            CANCEL
          </div>
        </div>
      </div>
    );
  }
}

class Remove extends React.Component {
  render() {
    return (
      <div className="popupContainer_InputTable">
        <div className="popupContainer_ButtonRow">
          Are you sure you want to remove the '
          <b>{this.props.Division.Squadrons[this.props.SquadronIndex].Name}</b>'
          squadron from the '<b>{this.props.Division.Name}</b>' division?
        </div>
        <div className="popupContainer_ButtonRow">
          <div
            className="popupContainer_Button"
            onMouseDown={() => {
              Utilities.arrayRemoveElement(
                this.props.Division.Squadrons,
                this.props.SquadronIndex
              );
              this.props.Refresh();
              PopupContainer.HidePopup();
            }}
          >
            YES
          </div>
          <div
            className="popupContainer_Button"
            onClick={() => {
              PopupContainer.HidePopup();
            }}
          >
            NO
          </div>
        </div>
      </div>
    );
  }
}

class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Name: this.props.Division.Squadrons[this.props.SquadronIndex].Name,
      CommentsValue: this.props.Division.Squadrons[this.props.SquadronIndex]
        .Comments,
      Name_Uid: Utilities.Guid(),
      Comments_Uid: Utilities.Guid()
    };

    this.NameChangeHandler = this.NameChangeHandler.bind(this);
    this.CommentChangeHandler = this.CommentChangeHandler.bind(this);
    this.KeyDownHandler = this.KeyDownHandler.bind(this);
  }

  NameChangeHandler(e) {
    this.setState({ Name: e.target.value });
  }

  CommentChangeHandler(e) {
    this.setState({ CommentsValue: e.target.value });
  }

  Commit(name, comments) {
    if (name.length === 0) PopupContainer.ShowError("Name field is required.");
    else {
      let _division = this.props.Division;
      _division.Squadrons[this.props.SquadronIndex].Name = name;
      _division.Squadrons[this.props.SquadronIndex].Comments = comments;
      this.props.Refresh();
      PopupContainer.HidePopup();
    }
  }

  KeyDownHandler(e) {
    if (e.keyCode === 13)
      this.Commit(this.state.Name, this.state.CommentsValue);
    else if (e.keyCode === 27) PopupContainer.HidePopup();
  }

  componentDidMount() {
    let _elem = document.getElementById(this.state.Name_Uid);
    window.setTimeout(() => {
      _elem.focus();
      _elem.select();
    }, 1);
  }

  render() {
    return (
      <div className="popupContainer_InputTable">
        <div className="popupContainer_ButtonRow">
          <p style={{ marginBottom: "2vh" }}>
            Update the squadron name for the '<b>{this.props.Division.Name}</b>'
            division.
          </p>
        </div>
        <div className="pupupContainer_InputLabel">Name</div>
        <input
          className="pupupContainer_InputObject"
          type="text"
          style={{
            border: "rgba(0, 0, 0, 1) 1px solid"
          }}
          id={this.state.Name_Uid}
          onChange={this.NameChangeHandler}
          value={this.state.Name}
          onKeyDown={this.KeyDownHandler}
        />
        <div className="pupupContainer_InputLabel">Comments</div>
        <textarea
          className="pupupContainer_InputObject"
          rows="12"
          style={{
            border: "rgba(0, 0, 0, 1) 1px solid",
            minWidth: "15vw",
            resize: "none"
          }}
          id={this.state.Comments_Uid}
          onChange={this.CommentChangeHandler}
          value={this.state.CommentsValue}
          onKeyDown={this.KeyDownHandler}
        />
        <div id="popupContainer_ErrorMessage" />
        <div className="popupContainer_ButtonRow">
          <div
            className="popupContainer_Button"
            onMouseDown={() => {
              this.Commit(this.state.Name, this.state.CommentsValue);
            }}
          >
            SAVE
          </div>
          <div
            className="popupContainer_Button"
            onClick={() => {
              PopupContainer.HidePopup();
            }}
          >
            CANCEL
          </div>
        </div>
      </div>
    );
  }
}

class Print extends React.Component {
  constructor(props) {
    super(props);
    this.MemberHoverHandler = this.MemberHoverHandler.bind(this);
  }

  SquadronContainerStyle(branch) {
    var _background = branch.Colors[DataStore.Color_Background];
    var _foreground = branch.Colors[DataStore.Color_Foreground];
    var _border = branch.Colors[DataStore.Color_Border];

    var _style = {
      color: Utilities.rgbaAsString(
        _foreground.R,
        _foreground.G,
        _foreground.B,
        _foreground.A
      ),
      backgroundColor: Utilities.rgbaAsString(
        _background.R,
        _background.G,
        _background.B,
        _background.A
      ),
      borderColor: Utilities.rgbaAsString(
        _border.R,
        _border.G,
        _border.B,
        _border.A
      ),
      gridRowStart: 2,
      gridRowEnd: 6,
      gridColumnStart: 4,
      gridColumnEnd: 5
    };
    return _style;
  }

  SquadronPersonnelContainerStyle(branch) {
    let _background = branch.Colors[DataStore.Color_Background];
    let _border = branch.Colors[DataStore.Color_Border];

    let _style = {
      color: Utilities.rgbaAsString(
        _background.R,
        _background.G,
        _background.B,
        _background.A
      ),
      backgroundColor: Utilities.rgbaAsString(
        _border.R,
        _border.G,
        _border.B,
        _border.A
      ),
      borderColor: Utilities.rgbaAsString(
        _border.R,
        _border.G,
        _border.B,
        _border.A
      )
    };
    return _style;
  }

  SquadronPersonnelEntryStyle(branch) {
    let _background = branch.Colors[DataStore.Color_Background];
    let _foreground = branch.Colors[DataStore.Color_Foreground];
    let _border = branch.Colors[DataStore.Color_Border];

    let _border_rgba = Utilities.rgbaAsString(
      _border.R,
      _border.G,
      _border.B,
      _border.A
    );

    let _background_rgba = Utilities.rgbaAsString(
      _background.R,
      _background.G,
      _background.B,
      _background.A
    );

    var _style = {
      color: Utilities.rgbaAsString(
        _foreground.R,
        _foreground.G,
        _foreground.B,
        _foreground.A
      ),
      background:
        "linear-gradient(270deg, " +
        _border_rgba +
        " 10%, " +
        _background_rgba +
        " 100%)"
    };
    return _style;
  }

  SquadronContainerVerticalTree(index, branch) {
    let _border = branch.Colors[DataStore.Color_Border];
    let _end = index < this.props.Division.Squadrons.length - 1 ? 7 : 3;

    let _style = {
      backgroundColor: Utilities.rgbaAsString(
        _border.R,
        _border.G,
        _border.B,
        _border.A
      ),
      gridColumnStart: 2,
      gridColumnEnd: 3,
      gridRowStart: 1,
      gridRowEnd: _end
    };

    return _style;
  }

  SquadronContainerHorizontalTree(branch) {
    let _border = branch.Colors[DataStore.Color_Border];
    let _style = {
      backgroundColor: Utilities.rgbaAsString(
        _border.R,
        _border.G,
        _border.B,
        _border.A
      ),
      gridColumnStart: 2,
      gridColumnEnd: 4,
      gridRowStart: 3,
      gridRowEnd: 4
    };

    return _style;
  }

  MemberHoverHandler() {
    for (let j = 0; j < DataStore.User.Branches.length; j++) {
      let _branch = DataStore.User.Branches[j];
      for (let k = 0; k < _branch.Divisions.length; k++) {
        let _division = _branch.Divisions[k];
        for (let l = 0; l < _division.Squadrons.length; l++) {
          let _squadron = document.getElementById(_division.Squadrons[l].Uid);
          if (
            DataStore.SelectedMember.length > 0 &&
            window.event.clientX >= _squadron.offsetLeft &&
            window.event.clientX <=
              _squadron.offsetLeft + _squadron.offsetWidth &&
            window.event.clientY >= _squadron.offsetTop &&
            window.event.clientY <= _squadron.offsetTop + _squadron.offsetHeight
          ) {
            _squadron.style.borderColor = "lime";
          } else
            _squadron.style.borderColor = this.SquadronContainerStyle(
              _branch
            ).borderColor;
        }
      }
    }
  }

  AddMemberForum(squadron, uid) {
    let _member = Utilities.getArrayElementByUid(
      DataStore.User.MemberList,
      uid
    );

    PopupContainer.ShowPopup(
      "ADD CREW TO " + squadron.Name.toUpperCase(),
      <div className="popupContainer_InputTable">
        <div className="popupContainer_ButtonRow">
          What position would you like to give <b>{_member.Handle}</b> for the{" "}
          <b>{squadron.Name}</b> squadron?
        </div>
        <div className="pupupContainer_InputLabel">Position</div>
        <select
          id="AddSquadronCrewPosition"
          className="pupupContainer_InputObject"
          style={{ border: "rgba(0, 0, 0, 1) 1px solid" }}
        >
          <option value="0"></option>
          <option value="1">First Command</option>
          <option value="2">Second Command</option>
          <option value="3">Crew</option>
        </select>
        <div id="popupContainer_ErrorMessage" />
        <div className="popupContainer_ButtonRow">
          <div
            className="popupContainer_Button"
            onMouseDown={() => {
              let _position =
                parseInt(
                  document.getElementById("AddSquadronCrewPosition").value
                ) - 1;
              if (_position === -1) {
                PopupContainer.ShowError(
                  "You must choose a position for your crew member."
                );
              } else if (_position >= 2) {
                squadron.Crew.push(_member);
                console.log(squadron);
                this.props.Refresh();
                PopupContainer.HidePopup();
              } else {
                squadron.Commanders[_position] = _member;
                this.props.Refresh();
                PopupContainer.HidePopup();
              }
            }}
          >
            ADD
          </div>
          <div
            className="popupContainer_Button"
            onClick={() => {
              PopupContainer.HidePopup();
            }}
          >
            CANCEL
          </div>
        </div>
      </div>
    );
  }

  RankGraphic(member) {
    let _list = [];
    for (let i = 5; i > 0; i--) {
      if (i <= member.Stars) {
        _list.push(
          <img
            key={"RankGraphic_" + member.Uid + "_" + i}
            alt=""
            src={RankOn}
            style={{ maxHeight: "0.6em" }}
          />
        );
      } else {
        _list.push(
          <img
            key={"RankGraphic_" + member.Uid + "_" + i}
            alt=""
            src={RankOff}
            style={{ maxHeight: "0.6em" }}
          />
        );
      }
    }
    return _list;
  }

  ParseRole(roles) {
    if (roles.indexOf(";") > 0) {
      return roles.split(";")[0];
    } else return roles;
  }

  RenderCommander(squadron, index) {
    let _commander = squadron.Commanders[index];

    if (_commander !== undefined && _commander !== null) {
      let _color =
        _commander.Type === "Member"
          ? Utilities.rgbaAsString(128, 224, 255, 1)
          : "yellow";
      return (
        <div className="memberManager_CrewInnerContainer">
          <img
            className="memberManager_Avatar"
            style={{ borderRadius: "4px", marginLeft: "0.33em" }}
            alt=""
            src={"https://robertsspaceindustries.com" + _commander.Avatar}
            onError={e =>
              (e.target.src =
                "https://robertsspaceindustries.com/rsi/static/images/account/avatar_default_big.jpg")
            }
          />
          <div className="memberManager_Handle" style={{ color: _color }}>
            {_commander.Handle}
          </div>
          <div className="memberManager_Moniker">{_commander.Moniker}</div>
          <div className="memberManager_Rank">
            {this.RankGraphic(_commander)}
          </div>
          <div className="memberManager_Roles">
            {this.ParseRole(_commander.Roles)}
          </div>
          <div
            style={{
              gridRow: "1/3",
              gridColumn: "4",
              fontSize: "0.9em",
              display: "grid",
              gridTemplateRows: "auto auto auto"
            }}
          >
            {index === 1 ? (
              !DataStore.User.ReadOnly ? (
                <div
                  className="crewContainer_MemberCardControl"
                  style={{
                    gridRow: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  onClick={() => {
                    Utilities.arrayMove(squadron.Commanders, 1, 0);
                    this.props.Refresh();
                  }}
                >
                  &#8896;
                </div>
              ) : (
                <div />
              )
            ) : (
              <div style={{ gridRow: 1 }}>&nbsp;</div>
            )}
            {!DataStore.User.ReadOnly ? (
              <div
                className="crewContainer_MemberCardControl"
                style={{
                  gridRow: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                onClick={() => {
                  squadron.Commanders[index] = null;
                  this.props.Refresh();
                }}
              >
                -
              </div>
            ) : (
              <div />
            )}
            {index === 0 ? (
              !DataStore.User.ReadOnly ? (
                <div
                  className="crewContainer_MemberCardControl"
                  style={{
                    gridRow: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  onClick={() => {
                    Utilities.arrayMove(squadron.Commanders, 0, 1);
                    this.props.Refresh();
                  }}
                >
                  &#8897;
                </div>
              ) : (
                <div />
              )
            ) : (
              <div style={{ gridRow: 3 }}>&nbsp;</div>
            )}
          </div>
        </div>
      );
    }
  }

  PopulateSquadrons(division) {
    let _squadron = [];
    for (let i = 0; i < division.Squadrons.length; i++)
      _squadron.push(
        <div
          key={"PopulateSquadrons_" + i}
          onMouseMove={this.MemberHoverHandler}
          onMouseLeave={this.MemberHoverHandler}
        >
          <div className="squadronContainer_Parent">
            <div
              style={this.SquadronContainerVerticalTree(i, this.props.Branch)}
            />
            <div
              style={this.SquadronContainerHorizontalTree(this.props.Branch)}
            />
            <div
              className="squadronContainer_Tile"
              id={division.Squadrons[i].Uid}
              style={this.SquadronContainerStyle(this.props.Branch)}
              onMouseUp={() => {
                if (DataStore.SelectedMember.length > 0)
                  this.AddMemberForum(
                    division.Squadrons[i],
                    DataStore.SelectedMember
                  );
              }}
            >
              <div className="squadronContainer_Title">
                {division.Squadrons[i].Name.toUpperCase()}
              </div>
              {!DataStore.User.ReadOnly ? (
                <SquadronControls
                  Refresh={this.props.Refresh}
                  Division={division}
                  SquadronIndex={i}
                  Squadron={division.Squadrons[i]}
                />
              ) : (
                <div />
              )}
              <div
                className="commandContainer_PersonnelParent"
                style={this.SquadronPersonnelContainerStyle(this.props.Branch)}
              >
                <div
                  className="commandContainer_PersonnelEntryOuter"
                  style={this.SquadronPersonnelEntryStyle(this.props.Branch)}
                >
                  <div style={{ minWidth: "0.75vw", fontSize: "0.6em" }}>
                    {1}
                  </div>
                  <div
                    className="commandContainer_PersonnelEntryInner"
                    style={this.SquadronPersonnelContainerStyle(
                      this.props.Branch
                    )}
                  >
                    {this.RenderCommander(division.Squadrons[i], 0)}
                  </div>
                </div>
                <div
                  className="commandContainer_PersonnelEntryOuter"
                  style={this.SquadronPersonnelEntryStyle(this.props.Branch)}
                >
                  <div style={{ minWidth: "0.75vw", fontSize: "0.6em" }}>
                    {2}
                  </div>
                  <div
                    className="commandContainer_PersonnelEntryInner"
                    style={this.SquadronPersonnelContainerStyle(
                      this.props.Branch
                    )}
                  >
                    {this.RenderCommander(division.Squadrons[i], 1)}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ gridRowStart: 6, gridColumnStart: 4 }}>
              {
                <CrewManager.Print
                  Refresh={this.props.Refresh}
                  Branch={this.props.Branch}
                  Squadron={division.Squadrons[i]}
                />
              }
            </div>
          </div>
        </div>
      );

    return _squadron;
  }

  render() {
    return this.PopulateSquadrons(this.props.Division);
  }
}

export { Add, Remove, Edit, Print };
