import * as Utilities from "./Utilities.js";

export const eBranchType = Object.freeze({
  Unset: 0,
  Security: 1,
  Science: 2,
  Exploration: 3,
  Sourcing: 4,
  Engineering: 5
});

export const eMemberManifestType = Object.freeze({ Url: 0, File: 1 });

export function UserData() {
  this.Uid = Utilities.Guid();
  this.ProjectName = "";
  this.OrganizationName = "";
  this.SpectrumId = "";
  this.OrganizationLogo = "";
  this.MemberManifestUrl = "";
  this.MemberManifestPath = "";
  this.MemberManifestType = 0;
  this.Branches = [];
  this.MemberList = [];
  this.PublishId = Utilities.Guid();
  this.ReadOnly = false;
}

export function BranchData(name, colors) {
  this.Uid = Utilities.Guid();
  this.Name = name;
  this.Colors = colors;
  this.Comments = "";
  this.Commanders = [];
  this.Divisions = [];
}

export function DivisionData(name) {
  this.Uid = Utilities.Guid();
  this.Name = name;
  this.Comments = "";
  this.Commanders = [];
  this.Squadrons = [];
}

export function SquadronData(name) {
  this.Uid = Utilities.Guid();
  this.Name = name;
  this.Comments = "";
  this.Commanders = [];
  this.Crew = [];
}

export function CrewData(name) {
  this.Uid = Utilities.Guid();
  this.Name = name;
  this.Avatar =
    "https://robertsspaceindustries.com/rsi/static/images/account/avatar_default_big.jpg";
  this.Rank = 0;
}

export function NodeData(name, type, branch, parent) {
  this.Uid = Utilities.Guid();
  this.Branch = branch;
  this.Parent = parent;
  this.Name = name;
  this.Type = type;
  this.Members = [];
}

export function MemberData(handle, moniker, stars, avatar) {
  this.Uid = Utilities.Guid();
  this.Handle = handle;
  this.Moniker = moniker;
  this.Rank = "";
  this.Roles = "";
  this.Avatar = avatar;
  this.Stars = stars;
  this.Dossier = "";
}

export function MemberDataFromJson(json) {
  let _member = new MemberData(
    json.Handle,
    json.Moniker,
    json.Stars,
    json.Avatar
  );
  _member.Rank = json.Rank;
  _member.Roles = json.Roles;
  _member.Stars = json.Stars;
  _member.Dossier = json.Dossier;
  _member.Type = json.Type;
  return _member;
}
