import React from "react";
import DataStore from "./DataStore.js";
import ColorPicker from "./ColorPicker.js";
import PopupContainer from "./PopupContainer.js";
import BranchControls from "./BranchControls.js";
import BranchTemplates from "./BranchTemplates.js";
import * as DataTypes from "./DataTypes.js";
import * as Utilities from "./Utilities.js";
import * as DivisionManager from "./DivisionManager.js";
import RankOn from "../images/rank_on.svg";
import RankOff from "../images/rank_off.svg";

class Add extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      BackgroudColor: {
        R: 0,
        G: 0,
        B: 0,
        A: (1).toFixed(2)
      },
      ForegroudColor: {
        R: 0,
        G: 0,
        B: 0,
        A: (1).toFixed(2)
      },
      BorderColor: {
        R: 0,
        G: 0,
        B: 0,
        A: (1).toFixed(2)
      },
      Name_Uid: Utilities.Guid(),
      Comments_Uid: Utilities.Guid(),
      SelectedIndex: 0,
      SelectedName: "",
      CommentsValue: ""
    };

    this.TemplateChange = this.TemplateChange.bind(this);
    this.NameChangeHandler = this.NameChangeHandler.bind(this);
    this.CommentChangeHandler = this.CommentChangeHandler.bind(this);
    this.KeyDownHandler = this.KeyDownHandler.bind(this);
  }

  TemplateChange(e) {
    let _back, _fore, _border, _name;
    switch (parseInt(e.target.value)) {
      case DataTypes.eBranchType.Unset:
        _back = BranchTemplates.Unset().Colors[0];
        _fore = BranchTemplates.Unset().Colors[1];
        _border = BranchTemplates.Unset().Colors[2];
        _name = BranchTemplates.Unset().Name;
        break;
      case DataTypes.eBranchType.Security:
        _back = BranchTemplates.Security().Colors[0];
        _fore = BranchTemplates.Security().Colors[1];
        _border = BranchTemplates.Security().Colors[2];
        _name = BranchTemplates.Security().Name;
        break;
      case DataTypes.eBranchType.Science:
        _back = BranchTemplates.Science().Colors[0];
        _fore = BranchTemplates.Science().Colors[1];
        _border = BranchTemplates.Science().Colors[2];
        _name = BranchTemplates.Science().Name;
        break;
      case DataTypes.eBranchType.Exploration:
        _back = BranchTemplates.Exploration().Colors[0];
        _fore = BranchTemplates.Exploration().Colors[1];
        _border = BranchTemplates.Exploration().Colors[2];
        _name = BranchTemplates.Exploration().Name;
        break;
      case DataTypes.eBranchType.Sourcing:
        _back = BranchTemplates.Sourcing().Colors[0];
        _fore = BranchTemplates.Sourcing().Colors[1];
        _border = BranchTemplates.Sourcing().Colors[2];
        _name = BranchTemplates.Sourcing().Name;
        break;
      case DataTypes.eBranchType.Engineering:
        _back = BranchTemplates.Engineering().Colors[0];
        _fore = BranchTemplates.Engineering().Colors[1];
        _border = BranchTemplates.Engineering().Colors[2];
        _name = BranchTemplates.Engineering().Name;
        break;
      default:
        break;
    }
    this.setState({
      BackgroudColor: {
        R: _back.R,
        G: _back.G,
        B: _back.B,
        A: _back.A
      },
      ForegroudColor: {
        R: _fore.R,
        G: _fore.G,
        B: _fore.B,
        A: _fore.A
      },
      BorderColor: {
        R: _border.R,
        G: _border.G,
        B: _border.B,
        A: _border.A
      },
      SelectedIndex: parseInt(e.target.value),
      SelectedName: _name
    });
    this.forceUpdate();
  }

  NameChangeHandler(e) {
    this.setState({ SelectedName: e.target.value });
  }

  CommentChangeHandler(e) {
    this.setState({ CommentsValue: e.target.value });
  }

  Commit(name, background, foreground, border, comments) {
    if (name.length === 0) PopupContainer.ShowError("Name field is required.");
    else if (
      JSON.stringify(background) === JSON.stringify(foreground) ||
      JSON.stringify(background) === JSON.stringify(border) ||
      JSON.stringify(foreground) === JSON.stringify(border)
    )
      PopupContainer.ShowError(
        "Background, Foreground and Border colors must be unique."
      );
    else {
      let _branch = new DataTypes.BranchData(name, [
        background,
        foreground,
        border
      ]);
      _branch.Comments = comments;
      DataStore.User.Branches.push(_branch);
      this.props.Refresh();
      PopupContainer.HidePopup();
    }
  }

  KeyDownHandler(e) {
    if (e.keyCode === 13)
      this.Commit(
        this.state.SelectedName,
        this.state.BackgroudColor,
        this.state.ForegroudColor,
        this.state.BorderColor
      );
    else if (e.keyCode === 27) PopupContainer.HidePopup();
  }

  componentDidMount() {
    let _elem = document.getElementById(this.state.Name_Uid);
    window.setTimeout(() => {
      _elem.focus();
      _elem.select();
    }, 1);
  }

  render() {
    return (
      <div className="popupContainer_InputTable">
        <div className="pupupContainer_InputLabel">Template</div>
        <select
          className="pupupContainer_InputObject"
          style={{ border: "rgba(0, 0, 0, 1) 1px solid" }}
          onChange={this.TemplateChange}
          value={this.state.SelectedIndex}
        >
          <option value="0">None</option>
          <option value="1">Security</option>
          <option value="2">Science</option>
          <option value="3">Exploration</option>
          <option value="4">Sourcing</option>
          <option value="5">Engineering</option>
        </select>
        <div className="pupupContainer_InputLabel">Name</div>
        <input
          className="pupupContainer_InputObject"
          type="text"
          style={{ border: "rgba(0, 0, 0, 1) 1px solid" }}
          id={this.state.Name_Uid}
          value={this.state.SelectedName}
          onChange={this.NameChangeHandler}
          onKeyDown={this.KeyDownHandler}
        />
        <div className="pupupContainer_InputLabel">Background</div>
        <div
          className="pupupContainer_InputObject"
          style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        >
          {<ColorPicker Color={this.state.BackgroudColor} />}
        </div>
        <div className="pupupContainer_InputLabel">Foreground</div>
        <div
          className="pupupContainer_InputObject"
          style={{ backgroundColor: "rgba(0,0,0,0)" }}
        >
          {<ColorPicker Color={this.state.ForegroudColor} />}
        </div>
        <div className="pupupContainer_InputLabel">Border</div>
        <div
          className="pupupContainer_InputObject"
          style={{ backgroundColor: "rgba(0,0,0,0)" }}
        >
          {<ColorPicker Color={this.state.BorderColor} />}
        </div>
        <div className="pupupContainer_InputLabel">Comments</div>
        <textarea
          className="pupupContainer_InputObject"
          rows="12"
          style={{
            border: "rgba(0, 0, 0, 1) 1px solid",
            resize: "none"
          }}
          id={this.state.Comments_Uid}
          onChange={this.CommentChangeHandler}
          value={this.state.CommentsValue}
          onKeyDown={this.KeyDownHandler}
        />
        <div id="popupContainer_ErrorMessage" />
        <div className="popupContainer_ButtonRow">
          <div
            className="popupContainer_Button"
            onMouseDown={() => {
              this.Commit(
                this.state.SelectedName,
                this.state.BackgroudColor,
                this.state.ForegroudColor,
                this.state.BorderColor,
                this.state.CommentsValue
              );
            }}
          >
            ADD
          </div>
          <div
            className="popupContainer_Button"
            onClick={() => {
              PopupContainer.HidePopup();
            }}
          >
            CANCEL
          </div>
        </div>
      </div>
    );
  }
}

class Remove extends React.Component {
  render() {
    return (
      <div className="popupContainer_InputTable">
        <div className="popupContainer_ButtonRow">
          Are you sure you want to remove the '
          <b>{DataStore.User.Branches[this.props.Index].Name}</b>' branch?
        </div>
        <div className="popupContainer_ButtonRow">
          <div
            className="popupContainer_Button"
            onMouseDown={() => {
              Utilities.arrayRemoveElement(
                DataStore.User.Branches,
                this.props.Index
              );
              this.props.Refresh();
              PopupContainer.HidePopup();
            }}
          >
            YES
          </div>
          <div
            className="popupContainer_Button"
            onClick={() => {
              PopupContainer.HidePopup();
            }}
          >
            NO
          </div>
        </div>
      </div>
    );
  }
}

class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      BackgroudColor: {
        R:
          DataStore.User.Branches[props.Index].Colors[
            DataStore.Color_Background
          ].R,
        G:
          DataStore.User.Branches[props.Index].Colors[
            DataStore.Color_Background
          ].G,
        B:
          DataStore.User.Branches[props.Index].Colors[
            DataStore.Color_Background
          ].B,
        A:
          DataStore.User.Branches[props.Index].Colors[
            DataStore.Color_Background
          ].A
      },
      ForegroudColor: {
        R:
          DataStore.User.Branches[props.Index].Colors[
            DataStore.Color_Foreground
          ].R,
        G:
          DataStore.User.Branches[props.Index].Colors[
            DataStore.Color_Foreground
          ].G,
        B:
          DataStore.User.Branches[props.Index].Colors[
            DataStore.Color_Foreground
          ].B,
        A:
          DataStore.User.Branches[props.Index].Colors[
            DataStore.Color_Foreground
          ].A
      },
      BorderColor: {
        R:
          DataStore.User.Branches[props.Index].Colors[DataStore.Color_Border].R,
        G:
          DataStore.User.Branches[props.Index].Colors[DataStore.Color_Border].G,
        B:
          DataStore.User.Branches[props.Index].Colors[DataStore.Color_Border].B,
        A: DataStore.User.Branches[props.Index].Colors[DataStore.Color_Border].A
      },
      Name_Uid: Utilities.Guid(),
      Comment_Uid: Utilities.Guid(),
      SelectedName: DataStore.User.Branches[props.Index].Name,
      CommentsValue: DataStore.User.Branches[props.Index].Comments
    };
    this.NameChangeHandler = this.NameChangeHandler.bind(this);
    this.CommentChangeHandler = this.CommentChangeHandler.bind(this);
    this.KeyDownHandler = this.KeyDownHandler.bind(this);
  }

  NameChangeHandler(e) {
    this.setState({ SelectedName: e.target.value });
  }

  CommentChangeHandler(e) {
    this.setState({ CommentsValue: e.target.value });
  }

  Commit(name, background, foreground, border, comments) {
    if (name.length === 0) PopupContainer.ShowError("Name field is required.");
    else if (
      JSON.stringify(background) === JSON.stringify(foreground) ||
      JSON.stringify(background) === JSON.stringify(border) ||
      JSON.stringify(foreground) === JSON.stringify(border)
    )
      PopupContainer.ShowError(
        "Background, Foreground and Border colors must be unique."
      );
    else {
      let _branch = new DataTypes.BranchData(name, [
        background,
        foreground,
        border
      ]);
      _branch.Comments = comments;
      _branch.Commanders = DataStore.User.Branches[this.props.Index].Commanders;
      _branch.Divisions = DataStore.User.Branches[this.props.Index].Divisions;
      DataStore.User.Branches[this.props.Index] = _branch;
      this.props.Refresh();
      PopupContainer.HidePopup();
    }
  }

  KeyDownHandler(e) {
    if (e.keyCode === 13)
      this.Commit(
        this.state.SelectedName,
        this.state.BackgroudColor,
        this.state.ForegroudColor,
        this.state.BorderColor
      );
    else if (e.keyCode === 27) PopupContainer.HidePopup();
  }

  componentDidMount() {
    let _elem = document.getElementById(this.state.Name_Uid);
    window.setTimeout(() => {
      _elem.focus();
      _elem.select();
    }, 1);
  }

  render() {
    return (
      <div className="popupContainer_InputTable">
        <div className="pupupContainer_InputLabel">Name</div>
        <input
          className="pupupContainer_InputObject"
          type="text"
          style={{ border: "rgba(0, 0, 0, 1) 1px solid" }}
          id={this.state.Name_Uid}
          onChange={this.NameChangeHandler}
          value={this.state.SelectedName}
          onKeyDown={this.KeyDownHandler}
        />
        <div className="pupupContainer_InputLabel">Background</div>
        <div
          className="pupupContainer_InputObject"
          style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        >
          {<ColorPicker Color={this.state.BackgroudColor} />}
        </div>
        <div className="pupupContainer_InputLabel">Foreground</div>
        <div
          className="pupupContainer_InputObject"
          style={{ backgroundColor: "rgba(0,0,0,0)" }}
        >
          {<ColorPicker Color={this.state.ForegroudColor} />}
        </div>
        <div className="pupupContainer_InputLabel">Border</div>
        <div
          className="pupupContainer_InputObject"
          style={{ backgroundColor: "rgba(0,0,0,0)" }}
        >
          {<ColorPicker Color={this.state.BorderColor} />}
        </div>
        <div className="pupupContainer_InputLabel">Comments</div>
        <textarea
          className="pupupContainer_InputObject"
          rows="12"
          style={{
            border: "rgba(0, 0, 0, 1) 1px solid",
            resize: "none"
          }}
          id={this.state.Comments_Uid}
          onChange={this.CommentChangeHandler}
          value={this.state.CommentsValue}
          onKeyDown={this.KeyDownHandler}
        />
        <div id="popupContainer_ErrorMessage" />
        <div className="popupContainer_ButtonRow">
          <div
            className="popupContainer_Button"
            onMouseDown={() => {
              this.Commit(
                this.state.SelectedName,
                this.state.BackgroudColor,
                this.state.ForegroudColor,
                this.state.BorderColor,
                this.state.CommentsValue
              );
            }}
          >
            SAVE
          </div>
          <div
            className="popupContainer_Button"
            onClick={() => {
              PopupContainer.HidePopup();
            }}
          >
            CANCEL
          </div>
        </div>
      </div>
    );
  }
}

class Print extends React.Component {
  constructor(props) {
    super(props);
    this.RefreshHandler = this.RefreshHandler.bind(this);
    this.MemberHoverHandler = this.MemberHoverHandler.bind(this);
  }

  BranchContainerStyle(index) {
    var _branch = DataStore.User.Branches[index];
    var _background = _branch.Colors[DataStore.Color_Background];
    var _foreground = _branch.Colors[DataStore.Color_Foreground];
    var _border = _branch.Colors[DataStore.Color_Border];

    var _style = {
      color: Utilities.rgbaAsString(
        _foreground.R,
        _foreground.G,
        _foreground.B,
        _foreground.A
      ),
      backgroundColor: Utilities.rgbaAsString(
        _background.R,
        _background.G,
        _background.B,
        _background.A
      ),
      borderColor: Utilities.rgbaAsString(
        _border.R,
        _border.G,
        _border.B,
        _border.A
      )
    };
    return _style;
  }

  BranchPersonnelContainerStyle(index) {
    let _branch = DataStore.User.Branches[index];
    let _background = _branch.Colors[DataStore.Color_Background];
    let _border = _branch.Colors[DataStore.Color_Border];

    let _style = {
      color: Utilities.rgbaAsString(
        _background.R,
        _background.G,
        _background.B,
        _background.A
      ),
      backgroundColor: Utilities.rgbaAsString(
        _border.R,
        _border.G,
        _border.B,
        _border.A
      ),
      borderColor: Utilities.rgbaAsString(
        _border.R,
        _border.G,
        _border.B,
        _border.A
      )
    };
    return _style;
  }

  BranchPersonnelEntryStyle(index) {
    let _branch = DataStore.User.Branches[index];
    let _background = _branch.Colors[DataStore.Color_Background];
    let _foreground = _branch.Colors[DataStore.Color_Foreground];
    let _border = _branch.Colors[DataStore.Color_Border];

    let _border_rgba = Utilities.rgbaAsString(
      _border.R,
      _border.G,
      _border.B,
      _border.A
    );

    let _background_rgba = Utilities.rgbaAsString(
      _background.R,
      _background.G,
      _background.B,
      _background.A
    );

    var _style = {
      color: Utilities.rgbaAsString(
        _foreground.R,
        _foreground.G,
        _foreground.B,
        _foreground.A
      ),
      background:
        "linear-gradient(270deg, " +
        _border_rgba +
        " 10%, " +
        _background_rgba +
        " 100%)"
    };
    return _style;
  }

  RefreshHandler() {
    this.forceUpdate();
  }

  MemberHoverHandler() {
    for (let i = 0; i < DataStore.User.Branches.length; i++) {
      let _branch = document.getElementById(DataStore.User.Branches[i].Uid);
      if (
        DataStore.SelectedMember.length > 0 &&
        window.event.clientX >= _branch.offsetLeft &&
        window.event.clientX <= _branch.offsetLeft + _branch.offsetWidth &&
        window.event.clientY >= _branch.offsetTop &&
        window.event.clientY <= _branch.offsetTop + _branch.offsetHeight
      ) {
        _branch.style.borderColor = "lime";
      } else
        _branch.style.borderColor = this.BranchContainerStyle(i).borderColor;
    }
  }

  AddMemberForum(branch, uid) {
    let _member = Utilities.getArrayElementByUid(
      DataStore.User.MemberList,
      uid
    );

    PopupContainer.ShowPopup(
      "ADD CREW TO " + branch.Name.toUpperCase(),
      <div className="popupContainer_InputTable">
        <div className="popupContainer_ButtonRow">
          What position would you like to give <b>{_member.Handle}</b> for the{" "}
          <b>{branch.Name}</b> branch?
        </div>
        <div className="pupupContainer_InputLabel">Position</div>
        <select
          id="AddBranchCrewPosition"
          className="pupupContainer_InputObject"
          style={{ border: "rgba(0, 0, 0, 1) 1px solid" }}
        >
          <option value="0"></option>
          <option value="1">First Command</option>
          <option value="2">Second Command</option>
        </select>
        <div id="popupContainer_ErrorMessage" />
        <div className="popupContainer_ButtonRow">
          <div
            className="popupContainer_Button"
            onMouseDown={() => {
              let _position =
                parseInt(
                  document.getElementById("AddBranchCrewPosition").value
                ) - 1;
              if (_position === -1) {
                PopupContainer.ShowError(
                  "You must choose a position for your crew member."
                );
              } else {
                branch.Commanders[_position] = _member;
                this.RefreshHandler();
                PopupContainer.HidePopup();
              }
            }}
          >
            ADD
          </div>
          <div
            className="popupContainer_Button"
            onClick={() => {
              PopupContainer.HidePopup();
            }}
          >
            CANCEL
          </div>
        </div>
      </div>
    );
  }

  RankGraphic(member) {
    let _list = [];
    for (let i = 5; i > 0; i--) {
      if (i <= member.Stars) {
        _list.push(
          <img
            key={"RankGraphic_" + member.Uid + "_" + i}
            alt=""
            src={RankOn}
            style={{ maxHeight: "0.6em" }}
          />
        );
      } else {
        _list.push(
          <img
            key={"RankGraphic_" + member.Uid + "_" + i}
            alt=""
            src={RankOff}
            style={{ maxHeight: "0.6em" }}
          />
        );
      }
    }
    return _list;
  }

  ParseRole(roles) {
    if (roles.indexOf(";") > 0) {
      return roles.split(";")[0];
    } else return roles;
  }

  RenderCommander(branch, index) {
    let _commander = branch.Commanders[index];

    if (_commander !== undefined && _commander !== null) {
      let _color =
        _commander.Type === "Member"
          ? Utilities.rgbaAsString(128, 224, 255, 1)
          : "yellow";
      return (
        <div className="memberManager_CrewInnerContainer">
          <img
            className="memberManager_Avatar"
            style={{ borderRadius: "4px", marginLeft: "0.33em" }}
            alt=""
            src={"https://robertsspaceindustries.com" + _commander.Avatar}
            onError={e =>
              (e.target.src =
                "https://robertsspaceindustries.com/rsi/static/images/account/avatar_default_big.jpg")
            }
          />
          <div className="memberManager_Handle" style={{ color: _color }}>
            {_commander.Handle}
          </div>
          <div className="memberManager_Moniker">{_commander.Moniker}</div>
          <div className="memberManager_Rank">
            {this.RankGraphic(_commander)}
          </div>
          <div className="memberManager_Roles">
            {this.ParseRole(_commander.Roles)}
          </div>
          <div
            style={{
              gridRow: "1/3",
              gridColumn: "4",
              fontSize: "0.9em",
              display: "grid",
              gridTemplateRows: "auto auto auto"
            }}
          >
            {index === 1 ? (
              !DataStore.User.ReadOnly ? (
                <div
                  className="crewContainer_MemberCardControl"
                  style={{
                    gridRow: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  onClick={() => {
                    Utilities.arrayMove(branch.Commanders, 1, 0);
                    this.RefreshHandler();
                  }}
                >
                  &#8896;
                </div>
              ) : (
                <div />
              )
            ) : (
              <div style={{ gridRow: 1 }}>&nbsp;</div>
            )}
            {!DataStore.User.ReadOnly ? (
              <div
                className="crewContainer_MemberCardControl"
                style={{
                  gridRow: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                onClick={() => {
                  branch.Commanders[index] = null;
                  this.RefreshHandler();
                }}
              >
                -
              </div>
            ) : (
              <div />
            )}
            {index === 0 ? (
              !DataStore.User.ReadOnly ? (
                <div
                  className="crewContainer_MemberCardControl"
                  style={{
                    gridRow: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  onClick={() => {
                    Utilities.arrayMove(branch.Commanders, 0, 1);
                    this.RefreshHandler();
                  }}
                >
                  &#8897;
                </div>
              ) : (
                <div />
              )
            ) : (
              <div style={{ gridRow: 3 }}>&nbsp;</div>
            )}
          </div>
        </div>
      );
    }
  }

  PopulateBranches() {
    let branches = [];
    for (let i = 0; i < DataStore.User.Branches.length; i++) {
      branches.push(
        <div
          key={"PopulateBranches_" + i}
          style={{ gridColumnStart: i, gridColumnEnd: i + 1 }}
          onMouseMove={this.MemberHoverHandler}
          onMouseLeave={this.MemberHoverHandler}
        >
          <div
            id={DataStore.User.Branches[i].Uid}
            className="commandContainer_Branch"
            style={this.BranchContainerStyle(i)}
            onMouseUp={() => {
              if (DataStore.SelectedMember.length > 0)
                this.AddMemberForum(
                  DataStore.User.Branches[i],
                  DataStore.SelectedMember
                );
            }}
          >
            <div className="commandContainer_BranchTitle">
              {DataStore.User.Branches[i].Name.toUpperCase()}
            </div>
            {!DataStore.User.ReadOnly ? (
              <BranchControls Refresh={this.RefreshHandler} Index={i} />
            ) : (
              <div />
            )}
            <div
              className="commandContainer_PersonnelParent"
              style={this.BranchPersonnelContainerStyle(i)}
            >
              <div
                className="commandContainer_PersonnelEntryOuter"
                style={this.BranchPersonnelEntryStyle(i)}
              >
                <div style={{ minWidth: "0.75vw", fontSize: "0.6em" }}>{1}</div>
                <div
                  className="commandContainer_PersonnelEntryInner"
                  style={this.BranchPersonnelContainerStyle(i)}
                >
                  {this.RenderCommander(DataStore.User.Branches[i], 0)}
                </div>
              </div>
              <div
                className="commandContainer_PersonnelEntryOuter"
                style={this.BranchPersonnelEntryStyle(i)}
              >
                <div style={{ minWidth: "0.75vw", fontSize: "0.6em" }}>{2}</div>
                <div
                  className="commandContainer_PersonnelEntryInner"
                  style={this.BranchPersonnelContainerStyle(i)}
                >
                  {this.RenderCommander(DataStore.User.Branches[i], 1)}
                </div>
              </div>
            </div>
          </div>
          {
            <DivisionManager.Print
              Refresh={this.RefreshHandler}
              BranchIndex={i}
            />
          }
        </div>
      );
    }
    return branches;
  }

  render() {
    return (
      <div id="commandConatiner_BranchesParent">{this.PopulateBranches()}</div>
    );
  }
}

export { Add, Remove, Edit, Print };
