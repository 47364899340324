import DataStore from "../scripts/DataStore.js";

class FileOperation {
  OpenFile(forceUpdate) {
    let _form = document.createElement("form");
    _form.enctype = "multipart/form-data";
    _form.style.visibility = "hidden";

    let _inputFile = document.createElement("input");
    _inputFile.name = "files[]";
    _inputFile.accept = ".sccm";
    _inputFile.type = "file";
    _inputFile.onchange = () => {
      const _data = new FormData(_form);
      fetch(DataStore.DOMAIN + "process/open.php", {
        method: "POST",
        body: _data
      })
        .then(res => res.json())
        .then(data => (DataStore.User = data))
        .then(forceUpdate);
    };

    _form.appendChild(_inputFile);
    _inputFile.click();
  }

  ImportProperties(forceUpdate) {
    let _form = document.createElement("form");
    _form.enctype = "multipart/form-data";
    _form.style.visibility = "hidden";

    let _inputFile = document.createElement("input");
    _inputFile.name = "files[]";
    _inputFile.accept = ".sccmpp";
    _inputFile.type = "file";
    _inputFile.onchange = () => {
      const _data = new FormData(_form);
      fetch(DataStore.DOMAIN + "process/open.php", {
        method: "POST",
        body: _data
      })
        .then(res => res.json())
        .then(data => {
          DataStore.User.ProjectName =
            DataStore.User.ProjectName.length === 0
              ? data.ProjectName
              : DataStore.User.ProjectName;
          DataStore.User.OrganizationName = data.OrganizationName;
          DataStore.User.SpectrumId = data.SpectrumId;
          DataStore.User.OrganizationLogo = data.OrganizationLogo;
          DataStore.User.MemberManifestType = data.MemberManifestType;
          DataStore.User.MemberManifestUrl = data.MemberManifestUrl;
          DataStore.User.MemberManifestPath = data.MemberManifestPath;
        })
        .then(forceUpdate);
    };

    _form.appendChild(_inputFile);
    _inputFile.click();
  }

  UploadManifest(uid, file, forceUpdate) {
    let _form = document.createElement("form");
    _form.enctype = "multipart/form-data";
    _form.style.visibility = "hidden";
    _form.appendChild(file);

    let _uid = document.createElement("input");
    _uid.name = "uid";
    _uid.type = "text";
    _uid.value = uid;
    _form.appendChild(_uid);

    const _data = new FormData(_form);
    fetch(DataStore.DOMAIN + "process/manifest.php", {
      method: "POST",
      body: _data
    })
      .then(res => res.json())
      .then(forceUpdate);
  }

  SaveFile(file_name, content) {
    let _content = JSON.stringify(content)
      .replace(/ /g, "%20")
      .replace(/"/g, "%22")
      .replace(/'/g, "%27");

    let _elem = document.createElement("p");
    _elem.innerHTML =
      "<a id='download_link' href='data:application/json;charset=utf-8," +
      _content +
      "' download=" +
      file_name +
      "></a>";
    document.body.appendChild(_elem);

    let _link = document.getElementById("download_link");
    _link.click();

    document.body.removeChild(_elem);
  }

  WriteFile(file, content) {
    let _form = document.createElement("form");
    _form.enctype = "multipart/form-data";
    _form.style.visibility = "hidden";
    _form.method = "POST";
    _form.action = DataStore.DOMAIN + "process/write.php";

    let _inputPath = document.createElement("input");
    _inputPath.name = "path";
    _inputPath.type = "text";
    _inputPath.value = file;

    let _content = JSON.stringify(content);
    let _inputContent = document.createElement("input");
    _inputContent.name = "content";
    _inputContent.type = "text";
    _inputContent.value = _content;
    _inputContent.onselect = () => {
      const _data = new FormData(_form);
      fetch(DataStore.DOMAIN + "process/write.php", {
        method: "POST",
        body: _data
      }).then(res => res.json());
    };

    _form.appendChild(_inputPath);
    _form.appendChild(_inputContent);
    _inputContent.select();
  }
}

export default FileOperation;
